import React from 'react'
import {  tables , cabinets , shelves } from "../../products.jsx";
import { useParams } from 'react-router-dom';
import MetaData from "../../MetaData";
import {products} from './data.js'
import urban from '../../../src/imgs/Urban.svg'
import furniture from '../../../src/imgs/furniture.svg'
import { Product } from "./product.jsx";
import "./productdisplay.css"

const selectProductArray = (slug) => {
  switch(slug) {
    
    case 'tables':
      return tables;
    case 'cabinets':
      return cabinets;
    case 'shelves':
      return shelves;
    default:
      return []; // Return an empty array if no matching slug is found
  }
};

function ProductDisplay() {
    const { slug } = useParams();
    const productsToDisplay = selectProductArray(slug);
    const productInfo = products.find(item => item.title.toLowerCase() === slug.toLowerCase())
    
    const backgroundStyle = {
      backgroundImage: productInfo && productInfo.images.length > 0 ? `url(${productInfo.images})` : 'none',
    };
    console.log(productInfo?.images)
    
  return (
    <>
    <MetaData title={slug} />
    <div className='product-display-container'>
        <div className="pd-top" style={backgroundStyle}>
        <div class="text-boxx">
		<h1>{slug}</h1>
		<h1>{slug}</h1>
	</div>
            {/* <p>"𝙳𝚎𝚜𝚒𝚐𝚗𝚜 𝚝𝚑𝚊𝚝 𝙳𝚎𝚏𝚒𝚗𝚎, 𝙲𝚘𝚖𝚏𝚘𝚛𝚝𝚜 𝚝𝚑𝚊𝚝 𝙳𝚎𝚕𝚒𝚐𝚑𝚝: 𝙴𝚕𝚎𝚟𝚊𝚝𝚎 𝚈𝚘𝚞𝚛 𝚂𝚙𝚊𝚌𝚎 𝚠𝚒𝚝𝚑 𝙾𝚞𝚛 𝙰𝚛𝚝𝚏𝚞𝚕 𝙲𝚛𝚎𝚊𝚝𝚒𝚘𝚗𝚜."</p> */}
        </div>
        <div className="pd-bottom">
        <div className="shops">
      <div className="shopTitles">
     
      </div>

      <div className="productss">
        {productsToDisplay.map((product) => (
          <Product data={product} slug={slug}/>
        ))}
      </div>
    </div>
        </div>
    </div>
    </>
  )
}

export default ProductDisplay