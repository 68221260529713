import React, { useState, useCallback } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import Carousel from "react-images";
import MetaData from "../../MetaData";
import { useParams } from 'react-router-dom';
import {menu} from './data.js'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import ReactPlayer from "react-player";
import 'swiper/css/thumbs';

import './projectDisplay.css';

// import required modules
import { FreeMode, Navigation, Thumbs ,Autoplay } from 'swiper/modules';



function ProjectDisplay() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
     const [thumbsSwiper, setThumbsSwiper] = useState(null);
     const { slug } = useParams();
     const projectInfo = menu.find(item => item.title.toLowerCase() === slug.toLowerCase())
     
     const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
     console.log(projectInfo)
  return (
  <>
  <MetaData title={slug} />
  <div className='project-display-container'>
    <div className="project-slider">
        <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs , Autoplay]}
        className="mySwiper2"
      > 
        {
            projectInfo?.images.map(i =>(
                <SwiperSlide className='swiper-main-image'>
          <img src={i} />
        </SwiperSlide>
            ))
        }
        
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="swiper-bottom"
      >
        {
            projectInfo?.images.map(i =>(
                <SwiperSlide classname="swiper-bottom-part">
          <img src={i} />
        </SwiperSlide>
            ))
        }
        
      </Swiper>
    </div>
    <div className="project-display-desc">
        <div className="project-title">
              <h3 style={{color:"black" , fontSize:"2rem" , letterSpacing:"2px"}}>Design Brief</h3>
              <div className="text-line"></div>
        </div>
        <div className="project-display-main-desc">
            <div className="project-display-left">
                <div className="location">
                    <p className='pro-brief'>Project Name:</p>
                    <p className='pro-brief'>{projectInfo?.title}</p>
                </div>
                <div className="status">
                    <p className='pro-brief'>Client’s Name:</p>
                    <p className='pro-brief'>{projectInfo?.client}</p>
                </div>
                <div className="project-share">
                    <p className='pro-brief'>Site Address:</p>
                    <p className='pro-brief'>{projectInfo?.location}</p>
                </div>
            </div>
           
        </div>
       <div className="project-photos">
        <h1 style={{color:"black" , fontSize:"2rem" , letterSpacing:"2px" , textDecoration:"underline"}}>Project Images</h1>
       <Gallery className="galary-photo" photos={projectInfo?.images.map(image => ({ src: image }))} onClick={openLightbox} />
        <ModalGateway >
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox} >
              <Carousel
              
                currentIndex={currentImage}
                views={projectInfo?.images.map(image => ({
                  ...image,
                  src: image, // Ensure src property is set
                  srcset: image.srcSet || '', // Ensure srcSet property is set
                  caption: image.title || '', // Ensure title property is set
                  regular: image.regular || '', // Ensure regular property is set
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
       </div>
    </div>
  </div>
  </>
  )
}

export default ProjectDisplay