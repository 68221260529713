
import studytablewithmetalbase1 from "../src/imgs/Daraz/tables/01 - Study Table with Metal Base/Northbridge+Desk (1).jpg";
import studytablewithmetalbase2 from "../src/imgs/Daraz/tables/01 - Study Table with Metal Base/Northbridge+Desk (2).jpg";
import studytablewithmetalbase3 from "../src/imgs/Daraz/tables/01 - Study Table with Metal Base/Northbridge+Desk (3).jpg";
import studytablewithmetalbase4 from "../src/imgs/Daraz/tables/01 - Study Table with Metal Base/Northbridge+Desk.jpg";
import minimalstudytable1 from "../src/imgs/Daraz/tables/02 - Minimal Study Table/Aldora+120cm+W+Rectangle+Writing+Desk (1).jpg";
import minimalstudytable2 from "../src/imgs/Daraz/tables/02 - Minimal Study Table/Aldora+120cm+W+Rectangle+Writing+Desk (1).jpg";
import minimalstudytable3 from "../src/imgs/Daraz/tables/02 - Minimal Study Table/Aldora+120cm+W+Rectangle+Writing+Desk (1).jpg";
import minimalstudytable4 from "../src/imgs/Daraz/tables/02 - Minimal Study Table/Aldora+120cm+W+Rectangle+Writing+Desk (1).jpg";
import studydesk1 from "../src/imgs/Daraz/tables/03 - Study Desk/76.5cm H X 90cm W X 39.6cm D.jpg";
import studydesk2 from "../src/imgs/Daraz/tables/03 - Study Desk/Rivoli+Desk (1).jpg";
import studydesk3 from "../src/imgs/Daraz/tables/03 - Study Desk/Rivoli+Desk (2).jpg";
import studydesk4 from "../src/imgs/Daraz/tables/03 - Study Desk/Rivoli+Desk (4).jpg";
import studydesk5 from "../src/imgs/Daraz/tables/03 - Study Desk/Rivoli+Desk.jpg";
import studydeskwithdrawer1 from "../src/imgs/Daraz/tables/04 - Study Desk with Drawers/72cm H X 100cm W X 45cm D.jpg";
import studydeskwithdrawer2 from "../src/imgs/Daraz/tables/04 - Study Desk with Drawers/Stefanski+Computer+Desk (2).jpg";
import studydeskwithdrawer3 from "../src/imgs/Daraz/tables/04 - Study Desk with Drawers/Stefanski+Computer+Desk (3).jpg";
import studydeskwithdrawer4 from "../src/imgs/Daraz/tables/04 - Study Desk with Drawers/Stefanski+Computer+Desk.jpg";
import cornercomputerdesk1 from "../src/imgs/Daraz/tables/05 - Corner Computer Desk/Zytavious+72Cm+W+Corner+Computer+Desk (1).jpg";
import cornercomputerdesk2 from "../src/imgs/Daraz/tables/05 - Corner Computer Desk/Zytavious+72Cm+W+Corner+Computer+Desk (2).jpg";
import cornercomputerdesk3 from "../src/imgs/Daraz/tables/05 - Corner Computer Desk/Zytavious+72Cm+W+Corner+Computer+Desk (3).jpg";
import cornercomputerdesk4 from "../src/imgs/Daraz/tables/05 - Corner Computer Desk/Zytavious+72Cm+W+Corner+Computer+Desk (4).jpg";
import cornercomputerdesk5 from "../src/imgs/Daraz/tables/05 - Corner Computer Desk/Zytavious+72Cm+W+Corner+Computer+Desk.jpg";
import studydeskanddrawer1 from "../src/imgs/Daraz/tables/06 - Study Desk WIth Drawer/1.jpg";
import studydeskanddrawer2 from "../src/imgs/Daraz/tables/06 - Study Desk WIth Drawer/2.jpg";
import studydeskanddrawer3 from "../src/imgs/Daraz/tables/06 - Study Desk WIth Drawer/3.jpg";
import minimallongstudytable1 from "../src/imgs/Daraz/tables/07 - Minimal Long Study Table/1.jpg";
import minimallongstudytable2 from "../src/imgs/Daraz/tables/07 - Minimal Long Study Table/2.jpg";
import minimallongstudytable3 from "../src/imgs/Daraz/tables/07 - Minimal Long Study Table/3.jpg";
import minimallongstudytable4 from "../src/imgs/Daraz/tables/07 - Minimal Long Study Table/4.jpg";
import minimallongstudytable5 from "../src/imgs/Daraz/tables/07 - Minimal Long Study Table/5.jpg";
import minimallongstudytable6 from "../src/imgs/Daraz/tables/07 - Minimal Long Study Table/6.jpg";
import studytablewith2sidedshelf1 from "../src/imgs/Daraz/tables/08 - Study Table with 2 sided Shelf/1.jpg";
import studytablewith2sidedshelf2 from "../src/imgs/Daraz/tables/08 - Study Table with 2 sided Shelf/2.jpg";
import studytablewith2sidedshelf3 from "../src/imgs/Daraz/tables/08 - Study Table with 2 sided Shelf/3.jpg";
import studytablewith2sidedshelf4 from "../src/imgs/Daraz/tables/08 - Study Table with 2 sided Shelf/4.jpg";
import studytablewith2sidedshelf5 from "../src/imgs/Daraz/tables/08 - Study Table with 2 sided Shelf/5.jpg";
import studytablewith2sidedshelf6 from "../src/imgs/Daraz/tables/08 - Study Table with 2 sided Shelf/6.jpg";
import walldisplayshelf1 from "../src/imgs/Daraz/Shelf/1. Wall Display Shelf/1.jpg";
import walldisplayshelf2 from "../src/imgs/Daraz/Shelf/1. Wall Display Shelf/2.jpg";
import walldisplayshelf3 from "../src/imgs/Daraz/Shelf/1. Wall Display Shelf/3.jpg";
import walldisplayshelf4 from "../src/imgs/Daraz/Shelf/1. Wall Display Shelf/4.jpg";
import walldisplayshelf5 from "../src/imgs/Daraz/Shelf/1. Wall Display Shelf/5.jpg";
import fourpiecewallshelf1 from "../src/imgs/Daraz/Shelf/2. 4 piece Wall shelf/1.jpg";
import fourpiecewallshelf2 from "../src/imgs/Daraz/Shelf/2. 4 piece Wall shelf/2.jpg";
import fourpiecewallshelf3 from "../src/imgs/Daraz/Shelf/2. 4 piece Wall shelf/3.jpg";
import straightfloatingshelf1 from "../src/imgs/Daraz/Shelf/3. Straight Floating Shelf/1.jpg";
import straightfloatingshelf2 from "../src/imgs/Daraz/Shelf/3. Straight Floating Shelf/2.jpg";
import straightfloatingshelf3 from "../src/imgs/Daraz/Shelf/3. Straight Floating Shelf/3.jpg";
import twotierfloatingshelf1 from "../src/imgs/Daraz/Shelf/4. 2 Tier Floating Shelf/1.jpg";
import twotierfloatingshelf2 from "../src/imgs/Daraz/Shelf/4. 2 Tier Floating Shelf/2.jpg";
import twotierfloatingshelf3 from "../src/imgs/Daraz/Shelf/4. 2 Tier Floating Shelf/3.jpg";
import rectanglefloatingshelf1 from "../src/imgs/Daraz/Shelf/5. Rectangle Floating Shelf/1.jpg";
import rectanglefloatingshelf2 from "../src/imgs/Daraz/Shelf/5. Rectangle Floating Shelf/2.jpg";
import rectanglefloatingshelf3 from "../src/imgs/Daraz/Shelf/5. Rectangle Floating Shelf/3.jpg";
import rectanglefloatingshelf4 from "../src/imgs/Daraz/Shelf/5. Rectangle Floating Shelf/4.jpg";
import rectanglefloatingshelf5 from "../src/imgs/Daraz/Shelf/5. Rectangle Floating Shelf/5.jpg";
import upsidedownfloatingshelf1 from "../src/imgs/Daraz/Shelf/6. upside down floating shelf/1.jpg";
import upsidedownfloatingshelf2 from "../src/imgs/Daraz/Shelf/6. upside down floating shelf/2.jpg";
import upsidedownfloatingshelf3 from "../src/imgs/Daraz/Shelf/6. upside down floating shelf/3.jpg";
import upsidedownfloatingshelf4 from "../src/imgs/Daraz/Shelf/6. upside down floating shelf/4.jpg";
import upsidedownfloatingshelf5 from "../src/imgs/Daraz/Shelf/6. upside down floating shelf/5.jpg";
import inclinedfshelf1 from "../src/imgs/Daraz/Shelf/7 Inclined F SHELF/1.jpg";
import inclinedfshelf2 from "../src/imgs/Daraz/Shelf/7 Inclined F SHELF/2.jpg";
import inclinedfshelf3 from "../src/imgs/Daraz/Shelf/7 Inclined F SHELF/3.jpg";
import inclinedfshelf4 from "../src/imgs/Daraz/Shelf/7 Inclined F SHELF/4.jpg";
import floatingshelf1 from "../src/imgs/Daraz/Shelf/8. Floating Shelf/1.jpg";
import floatingshelf2 from "../src/imgs/Daraz/Shelf/8. Floating Shelf/2.jpg";
import floatingshelf3 from "../src/imgs/Daraz/Shelf/8. Floating Shelf/3.jpg";
import twopiecefloatingshelf1 from "../src/imgs/Daraz/Shelf/9. 2 Piece Floating Shelf/1.jpg";
import twopiecefloatingshelf2 from "../src/imgs/Daraz/Shelf/9. 2 Piece Floating Shelf/2.jpg";
import twopiecefloatingshelf3 from "../src/imgs/Daraz/Shelf/9. 2 Piece Floating Shelf/3.jpg";
import kitchenshelfwithhooks1 from "../src/imgs/Daraz/Shelf/10. KItchen Shelf with Hooks/1.jpg";
import kitchenshelfwithhooks2 from "../src/imgs/Daraz/Shelf/10. KItchen Shelf with Hooks/2.jpg";
import kitchenshelfwithhooks3 from "../src/imgs/Daraz/Shelf/10. KItchen Shelf with Hooks/3.jpg";
import kitchenshelfwithhooks4 from "../src/imgs/Daraz/Shelf/10. KItchen Shelf with Hooks/4.jpg";
import threetierkitchenshelf1 from "../src/imgs/Daraz/Shelf/11. 3 Tier Kitchen Shelf/1.jpg";
import threetierkitchenshelf2 from "../src/imgs/Daraz/Shelf/11. 3 Tier Kitchen Shelf/2.jpg";
import threetierkitchenshelf3 from "../src/imgs/Daraz/Shelf/11. 3 Tier Kitchen Shelf/3.jpg";
import threetierkitchenshelf4 from "../src/imgs/Daraz/Shelf/11. 3 Tier Kitchen Shelf/4.jpg";
import threetierkitchenshelf5 from "../src/imgs/Daraz/Shelf/11. 3 Tier Kitchen Shelf/5.jpg";
import threetierkitchenshelf6 from "../src/imgs/Daraz/Shelf/11. 3 Tier Kitchen Shelf/6.jpg";
import eightpiecefloatingshelf1 from "../src/imgs/Daraz/Shelf/12. 8 piece floating shelf/1.jpg";
import eightpiecefloatingshelf2 from "../src/imgs/Daraz/Shelf/12. 8 piece floating shelf/2.jpg";
import eightpiecefloatingshelf3 from "../src/imgs/Daraz/Shelf/12. 8 piece floating shelf/3.jpg";
import eightpiecefloatingshelf4 from "../src/imgs/Daraz/Shelf/12. 8 piece floating shelf/4.jpg";
import eightpiecefloatingshelf5 from "../src/imgs/Daraz/Shelf/12. 8 piece floating shelf/5.jpg";
import carmelafloatingshelf1 from "../src/imgs/Daraz/Shelf/13. carmela floating shelf/1.jpg";
import carmelafloatingshelf2 from "../src/imgs/Daraz/Shelf/13. carmela floating shelf/2.jpg";
import carmelafloatingshelf3 from "../src/imgs/Daraz/Shelf/13. carmela floating shelf/3.jpg";
import threepiecefloatingshelf1 from "../src/imgs/Daraz/Shelf/14. 3 piece floating shelf/1.jpg";
import threepiecefloatingshelf2 from "../src/imgs/Daraz/Shelf/14. 3 piece floating shelf/2.jpg";
import threepiecefloatingshelf3 from "../src/imgs/Daraz/Shelf/14. 3 piece floating shelf/3.jpg";
import openfloatingshelf1 from "../src/imgs/Daraz/Shelf/15. Open Floating Shelf/1.jpg";
import openfloatingshelf2 from "../src/imgs/Daraz/Shelf/15. Open Floating Shelf/2.jpg";
import openfloatingshelf3 from "../src/imgs/Daraz/Shelf/15. Open Floating Shelf/3.jpg";
import openfloatingshelf4 from "../src/imgs/Daraz/Shelf/15. Open Floating Shelf/4.jpg";
import wallshelf4piece1 from "../src/imgs/Daraz/Shelf/16. Wall Shelf 4 Piece/1.jpg";
import wallshelf4piece2 from "../src/imgs/Daraz/Shelf/16. Wall Shelf 4 Piece/2.jpg";
import wallshelf4piece3 from "../src/imgs/Daraz/Shelf/16. Wall Shelf 4 Piece/3.jpg";
import bassetfloatingshelf1 from "../src/imgs/Daraz/Shelf/17. Basset Floating Shelf/1.jpg";
import bassetfloatingshelf2 from "../src/imgs/Daraz/Shelf/17. Basset Floating Shelf/2.jpg";
import bassetfloatingshelf3 from "../src/imgs/Daraz/Shelf/17. Basset Floating Shelf/3.jpg";
import diezelfloatingshelf1 from "../src/imgs/Daraz/Shelf/18. Diezel floating shelf/1.jpg";
import diezelfloatingshelf2 from "../src/imgs/Daraz/Shelf/18. Diezel floating shelf/2.jpg";
import diezelfloatingshelf3 from "../src/imgs/Daraz/Shelf/18. Diezel floating shelf/3.jpg";
import cornor5pieceaccentshelf1 from "../src/imgs/Daraz/Shelf/19. Corner 5 piece accent shelf/1.jpg";
import cornor5pieceaccentshelf2 from "../src/imgs/Daraz/Shelf/19. Corner 5 piece accent shelf/2.jpg";
import threesquarefloatingshelf1 from "../src/imgs/Daraz/Shelf/21. 3 Square Floating Shelf/1.jpg";
import threetierwallshelf1 from "../src/imgs/Daraz/Shelf/22. 3 Tier Wall Shelf/1.jpg";
import threetierwallshelf2 from "../src/imgs/Daraz/Shelf/22. 3 Tier Wall Shelf/2.jpg";
import jabare5piecefloatingshelf1 from "../src/imgs/Daraz/Shelf/23. jabare 5 piece floating shelf/1.jpg";
import jabare5piecefloatingshelf2 from "../src/imgs/Daraz/Shelf/23. jabare 5 piece floating shelf/2.jpg";
import jabare5piecefloatingshelf3 from "../src/imgs/Daraz/Shelf/23. jabare 5 piece floating shelf/3.jpg";
import ledgewallshelf1 from "../src/imgs/Daraz/Shelf/24. Ledge Wall Shelf/1.jpg";
import ledgewallshelf2 from "../src/imgs/Daraz/Shelf/24. Ledge Wall Shelf/2.jpg";
import ledgewallshelf3 from "../src/imgs/Daraz/Shelf/24. Ledge Wall Shelf/3.jpg";
import twotierbookshelf1 from "../src/imgs/Daraz/Shelf/25. 2 Tier Book Shelf/1.jpg";
import twotierbookshelf2 from "../src/imgs/Daraz/Shelf/25. 2 Tier Book Shelf/2.jpg";
import ladderbookshelf1 from "../src/imgs/Daraz/Shelf/26. Ladder Book Shelf/1.jpg";
import ladderbookshelf2 from "../src/imgs/Daraz/Shelf/26. Ladder Book Shelf/2.jpg";
import ladderbookshelf3 from "../src/imgs/Daraz/Shelf/26. Ladder Book Shelf/3.jpg";
import ladderbookshelf4 from "../src/imgs/Daraz/Shelf/26. Ladder Book Shelf/4.jpg";
import ladderbookshelf5 from "../src/imgs/Daraz/Shelf/26. Ladder Book Shelf/5.jpg";
import standingbookdisplayrack1 from "../src/imgs/Daraz/Shelf/27. Standing Book Display Rack/1.jpg";
import standingbookdisplayrack2 from "../src/imgs/Daraz/Shelf/27. Standing Book Display Rack/2.jpg";
import standingbookdisplayrack3 from "../src/imgs/Daraz/Shelf/27. Standing Book Display Rack/3.jpg";
import standingbookdisplayrack4 from "../src/imgs/Daraz/Shelf/27. Standing Book Display Rack/4.jpg";
import standingbookdisplayrack5 from "../src/imgs/Daraz/Shelf/27. Standing Book Display Rack/5.jpg";
import fourshelfbookrack1 from "../src/imgs/Daraz/Shelf/28. Four Shelf Book Rack/1.jpg";
import fourshelfbookrack2 from "../src/imgs/Daraz/Shelf/28. Four Shelf Book Rack/2.jpg";
import roundcornorbookshelf1 from "../src/imgs/Daraz/Shelf/29. Round Corner Book Shelf/1.jpg";
import roundcornorbookshelf2 from "../src/imgs/Daraz/Shelf/29. Round Corner Book Shelf/2.jpg";
import roundcornorbookshelf3 from "../src/imgs/Daraz/Shelf/29. Round Corner Book Shelf/3.jpg";
import roundcornorbookshelf4 from "../src/imgs/Daraz/Shelf/29. Round Corner Book Shelf/4.jpg";
import roundcornorbookshelf5 from "../src/imgs/Daraz/Shelf/29. Round Corner Book Shelf/5.jpg";
import roundcornorbookshelf6 from "../src/imgs/Daraz/Shelf/29. Round Corner Book Shelf/6.jpg";
import sixshelfbookrack1 from "../src/imgs/Daraz/Shelf/31. Six Shelf Book Rack/1.jpg";
import fourtierbookshelf1 from "../src/imgs/Daraz/Shelf/34. Four Tier Book Shelf/1.jpg";
import squarebookshelf1 from "../src/imgs/Daraz/Shelf/35. Square Book Shelf/1.jpg";
import squarebookshelf2 from "../src/imgs/Daraz/Shelf/35. Square Book Shelf/2.jpg";
import squarebookshelf3 from "../src/imgs/Daraz/Shelf/35. Square Book Shelf/3.jpg";
import squarebookshelf4 from "../src/imgs/Daraz/Shelf/35. Square Book Shelf/4.jpg";
import squarebookshelf5 from "../src/imgs/Daraz/Shelf/35. Square Book Shelf/5.jpg";
import squarebookshelf6 from "../src/imgs/Daraz/Shelf/35. Square Book Shelf/6.jpg";
import UpholsteredShoeBench1 from "../src/imgs/Daraz/Cabinets/001-Upholstered Shoe Bench/1.jpg";
import UpholsteredShoeBench2 from "../src/imgs/Daraz/Cabinets/001-Upholstered Shoe Bench/2.jpg";
import UpholsteredShoeBench3 from "../src/imgs/Daraz/Cabinets/001-Upholstered Shoe Bench/3.jpg";
import UpholsteredShoeBench4 from "../src/imgs/Daraz/Cabinets/001-Upholstered Shoe Bench/4.jpg";
import UpholsteredShoeBench5 from "../src/imgs/Daraz/Cabinets/001-Upholstered Shoe Bench/5.jpg";
import UpholsteredShoeBench6 from "../src/imgs/Daraz/Cabinets/001-Upholstered Shoe Bench/6.jpg";
import UpholsteredStorageBench1 from "../src/imgs/Daraz/Cabinets/002-Upholstered Storage Bench/1.jpg";
import UpholsteredStorageBench2 from "../src/imgs/Daraz/Cabinets/002-Upholstered Storage Bench/2.jpg";
import UpholsteredStorageBench3 from "../src/imgs/Daraz/Cabinets/002-Upholstered Storage Bench/3.jpg";
import UpholsteredStorageBench4 from "../src/imgs/Daraz/Cabinets/002-Upholstered Storage Bench/4.jpg";
import UpholsteredStorageBench5 from "../src/imgs/Daraz/Cabinets/002-Upholstered Storage Bench/5.jpg";
import ShoeStorageCabinet1 from "../src/imgs/Daraz/Cabinets/003-Shoe Storage Cabinet/1.jpg";
import ShoeStorageCabinet2 from "../src/imgs/Daraz/Cabinets/003-Shoe Storage Cabinet/2.jpg";
import ShoeStorageCabinet3 from "../src/imgs/Daraz/Cabinets/003-Shoe Storage Cabinet/3.jpg";
import ShoeStorageCabinet4 from "../src/imgs/Daraz/Cabinets/003-Shoe Storage Cabinet/4.jpg";
import ShoeCabinetWithShutter1 from "../src/imgs/Daraz/Cabinets/004-Shoe Cabinet With Shutter/1.jpg";
import ShoeCabinetWithShutter2 from "../src/imgs/Daraz/Cabinets/004-Shoe Cabinet With Shutter/2.jpg";
import ShoeCabinetWithShutter3 from "../src/imgs/Daraz/Cabinets/004-Shoe Cabinet With Shutter/3.jpg";
import ShoeCabinetWithShutter4 from "../src/imgs/Daraz/Cabinets/004-Shoe Cabinet With Shutter/4.jpg";
import MeetingTable1 from "../src/imgs/Daraz/Cabinets/005 Meeting Table/1.webp";
import MeetingTable2 from "../src/imgs/Daraz/Cabinets/005 Meeting Table/2.webp";
import MeetingTable3 from "../src/imgs/Daraz/Cabinets/005 Meeting Table/3.webp";
import MeetingTable4 from "../src/imgs/Daraz/Cabinets/005 Meeting Table/4.webp";
import ThreeTierChestOfDrawers1 from "../src/imgs/Daraz/Cabinets/006-3 Tier Chest Of Drawers/1.jpg";
import ThreeTierChestOfDrawers2 from "../src/imgs/Daraz/Cabinets/006-3 Tier Chest Of Drawers/2.jpg";
import ThreeTierChestOfDrawers3 from "../src/imgs/Daraz/Cabinets/006-3 Tier Chest Of Drawers/3.jpg";
import ThreeTierChestOfDrawers4 from "../src/imgs/Daraz/Cabinets/006-3 Tier Chest Of Drawers/4.jpg";
import ThreeTierChestOfDrawers5 from "../src/imgs/Daraz/Cabinets/006-3 Tier Chest Of Drawers/5.jpg";
import TierChestOfDrawers1 from "../src/imgs/Daraz/Cabinets/007-5 Tier Chest Of Drawers/1.jpg";
import TierChestOfDrawers2 from "../src/imgs/Daraz/Cabinets/007-5 Tier Chest Of Drawers/2.jpg";
import TierChestOfDrawers3 from "../src/imgs/Daraz/Cabinets/007-5 Tier Chest Of Drawers/3.jpg";
import TierChestOfDrawers4 from "../src/imgs/Daraz/Cabinets/007-5 Tier Chest Of Drawers/4.jpg";
import HandlelesschestofDrawers1 from "../src/imgs/Daraz/Cabinets/008-Handleless chest of Drawers/1.jpg";
import HandlelesschestofDrawers2 from "../src/imgs/Daraz/Cabinets/008-Handleless chest of Drawers/2.jpg";
import HandlelesschestofDrawers3 from "../src/imgs/Daraz/Cabinets/008-Handleless chest of Drawers/3.jpg";
import BookCaseWith5ShelfAndDrawers1 from "../src/imgs/Daraz/Cabinets/009- Book Case With 5 Shelf And Drawers/1.webp";
import BookCaseWith5ShelfAndDrawers2 from "../src/imgs/Daraz/Cabinets/009- Book Case With 5 Shelf And Drawers/2.webp";
import BookCaseWith5ShelfAndDrawers3 from "../src/imgs/Daraz/Cabinets/009- Book Case With 5 Shelf And Drawers/3.webp";
import TVConsole6feet1 from "../src/imgs/Daraz/Cabinets/010 TV Console 6 feet/1.jpg";
import TVConsole6feet2 from "../src/imgs/Daraz/Cabinets/010 TV Console 6 feet/2.jpg";
import TVConsole6feet3 from "../src/imgs/Daraz/Cabinets/010 TV Console 6 feet/3.jpg";
import TVConsole6feet4 from "../src/imgs/Daraz/Cabinets/010 TV Console 6 feet/4.jpg";
import TVConsole6feet5 from "../src/imgs/Daraz/Cabinets/010 TV Console 6 feet/5.jpg";
import TwoShelfandDrawersTVconsole1 from "../src/imgs/Daraz/Cabinets/011 2 Shelf and Drawers TV console/1.jpg";
import TwoShelfandDrawersTVconsole2 from "../src/imgs/Daraz/Cabinets/011 2 Shelf and Drawers TV console/2.jpg";
import TwoShelfandDrawersTVconsole3 from "../src/imgs/Daraz/Cabinets/011 2 Shelf and Drawers TV console/3.jpg";
import TwoShelfandDrawersTVconsole4 from "../src/imgs/Daraz/Cabinets/011 2 Shelf and Drawers TV console/4.jpg";
import TwoShelfandDrawersTVconsole5 from "../src/imgs/Daraz/Cabinets/011 2 Shelf and Drawers TV console/5.jpg";
import TwoShelfandDrawersTVconsole6 from "../src/imgs/Daraz/Cabinets/011 2 Shelf and Drawers TV console/6.jpg";
import ThreeShelfandDrawerTvConsole1 from "../src/imgs/Daraz/Cabinets/012 3 Shelf and Drawer Tv Console/1.jpg";
import ThreeShelfandDrawerTvConsole2 from "../src/imgs/Daraz/Cabinets/012 3 Shelf and Drawer Tv Console/2.jpg";
import ThreeShelfandDrawerTvConsole3 from "../src/imgs/Daraz/Cabinets/012 3 Shelf and Drawer Tv Console/3.jpg";
import ThreeShelfandDrawerTvConsole4 from "../src/imgs/Daraz/Cabinets/012 3 Shelf and Drawer Tv Console/4.jpg";
import ThreeShelfandDrawerTvConsole5 from "../src/imgs/Daraz/Cabinets/012 3 Shelf and Drawer Tv Console/5.jpg";
import ThreeShelfandDrawerTvConsole6 from "../src/imgs/Daraz/Cabinets/012 3 Shelf and Drawer Tv Console/6.jpg";
import StepupBookCase1 from "../src/imgs/Daraz/Cabinets/013 Stepup BookCase/1.jpg";
import StepupBookCase2 from "../src/imgs/Daraz/Cabinets/013 Stepup BookCase/2.jpg";
import StepupBookCase3 from "../src/imgs/Daraz/Cabinets/013 Stepup BookCase/3.jpg";
import RectangleShelf1 from "../src/imgs/Daraz/Cabinets/014 Rectangle Shelf/1.webp";
import RectangleShelf2 from "../src/imgs/Daraz/Cabinets/014 Rectangle Shelf/2.webp";
import RectangleShelf3 from "../src/imgs/Daraz/Cabinets/014 Rectangle Shelf/3.webp";
import SquareShelf1 from "../src/imgs/Daraz/Cabinets/015 Square Shelf/1.jpg";
import SquareShelf2 from "../src/imgs/Daraz/Cabinets/015 Square Shelf/2.jpg";
import SquareShelf3 from "../src/imgs/Daraz/Cabinets/015 Square Shelf/3.jpg";
import SquareShelf4 from "../src/imgs/Daraz/Cabinets/015 Square Shelf/4.jpg";
import SquareShelf5 from "../src/imgs/Daraz/Cabinets/015 Square Shelf/5.jpg";
import FourTierOpenandClose1 from "../src/imgs/Daraz/Cabinets/016 Four Tier Open and Close/1.jpg";
import FourTierOpenandClose2 from "../src/imgs/Daraz/Cabinets/016 Four Tier Open and Close/2.jpg";
import FourTierOpenandClose3 from "../src/imgs/Daraz/Cabinets/016 Four Tier Open and Close/3.jpg";
import CakeShelf1 from "../src/imgs/Daraz/Cabinets/017-Cake Shelf/1.jpg";
import CakeShelf2 from "../src/imgs/Daraz/Cabinets/017-Cake Shelf/2.jpg";
import CakeShelf3 from "../src/imgs/Daraz/Cabinets/017-Cake Shelf/3.jpg";
import TierDrawers1 from "../src/imgs/Daraz/Cabinets/018 4 Tier Drawers/1.webp";
import TierDrawers2 from "../src/imgs/Daraz/Cabinets/018 4 Tier Drawers/2.webp";
import TierDrawers3 from "../src/imgs/Daraz/Cabinets/018 4 Tier Drawers/3.webp";
import TierDrawers4 from "../src/imgs/Daraz/Cabinets/018 4 Tier Drawers/4.webp";
import CubeBookcase1 from "../src/imgs/Daraz/Cabinets/019 Cube Bookcase/1.jpg";
import CubeBookcase2 from "../src/imgs/Daraz/Cabinets/019 Cube Bookcase/2.jpg";
import CubeBookcase3 from "../src/imgs/Daraz/Cabinets/019 Cube Bookcase/3.jpg";
import CubeBookcase4 from "../src/imgs/Daraz/Cabinets/019 Cube Bookcase/4.jpg";
import CubeBookcase5 from "../src/imgs/Daraz/Cabinets/019 Cube Bookcase/5.jpg";
import Twotierbookshelf1 from "../src/imgs/Daraz/Cabinets/020 2 tier book shelf/1.jpg";
import Twotierbookshelf2 from "../src/imgs/Daraz/Cabinets/020 2 tier book shelf/2.jpg";
import TVStandwithlegs1 from "../src/imgs/Daraz/Cabinets/021 TV Stand with legs/1.jpg";
import TVStandwithlegs2 from "../src/imgs/Daraz/Cabinets/021 TV Stand with legs/2.jpg";
import TVStandwithlegs3 from "../src/imgs/Daraz/Cabinets/021 TV Stand with legs/3.jpg";
import TVStandwithHandlessDrawers1 from "../src/imgs/Daraz/Cabinets/022 TV Stand with Handless Drawers/1.jpg";
import TVStandwithHandlessDrawers2 from "../src/imgs/Daraz/Cabinets/022 TV Stand with Handless Drawers/2.jpg";
import TVStandwithHandlessDrawers3 from "../src/imgs/Daraz/Cabinets/022 TV Stand with Handless Drawers/3.jpg";
import TVStandwithdrawers1 from "../src/imgs/Daraz/Cabinets/023 TV Stand with drawers/1.jpg";
import TVStandwithdrawers2 from "../src/imgs/Daraz/Cabinets/023 TV Stand with drawers/2.jpg";
import TVStandwithdrawers3 from "../src/imgs/Daraz/Cabinets/023 TV Stand with drawers/3.jpg";
import TVStandwithdrawers4 from "../src/imgs/Daraz/Cabinets/023 TV Stand with drawers/4.jpg";
import TVStandwithdrawers5 from "../src/imgs/Daraz/Cabinets/023 TV Stand with drawers/5.jpg";
import LshapeTVconsole1 from "../src/imgs/Daraz/Cabinets/024 L shape TV console/1.jpg";
import LshapeTVconsole2 from "../src/imgs/Daraz/Cabinets/024 L shape TV console/2.jpg";
import LshapeTVconsole3 from "../src/imgs/Daraz/Cabinets/024 L shape TV console/3.jpg";
import LshapeTVconsole4 from "../src/imgs/Daraz/Cabinets/024 L shape TV console/4.jpg";
import OpenAndCloseTVconsole1 from "../src/imgs/Daraz/Cabinets/025 Open And Close TV console/1.jpg";
import OpenAndCloseTVconsole2 from "../src/imgs/Daraz/Cabinets/025 Open And Close TV console/2.jpg";
import OpenAndCloseTVconsole3 from "../src/imgs/Daraz/Cabinets/025 Open And Close TV console/3.jpg";
import OpenAndCloseTVconsole4 from "../src/imgs/Daraz/Cabinets/025 Open And Close TV console/4.jpg";
import OpenAndCloseTVconsole5 from "../src/imgs/Daraz/Cabinets/025 Open And Close TV console/5.jpg";
import OpenAndCloseTVconsole6 from "../src/imgs/Daraz/Cabinets/025 Open And Close TV console/6.jpg";
import OpenAndCloseTVconsole7 from "../src/imgs/Daraz/Cabinets/025 Open And Close TV console/7.jpg";
import TVconsole2Tier1 from "../src/imgs/Daraz/Cabinets/026 TV console 2 Tier/1.jpg";
import TVconsole2Tier2 from "../src/imgs/Daraz/Cabinets/026 TV console 2 Tier/2.jpg";
import TVconsole2Tier3 from "../src/imgs/Daraz/Cabinets/026 TV console 2 Tier/3.jpg";
import ToyRackWith3Shelf1 from "../src/imgs/Daraz/Cabinets/027-Toy Rack With 3 Shelf/1.jpg";
import ToyRackWith3Shelf2 from "../src/imgs/Daraz/Cabinets/027-Toy Rack With 3 Shelf/2.jpg";
import ToyRackWith3Shelf3 from "../src/imgs/Daraz/Cabinets/027-Toy Rack With 3 Shelf/3.jpg";
import ToyRackWith3Shelf4 from "../src/imgs/Daraz/Cabinets/027-Toy Rack With 3 Shelf/4.jpg";
import ToyRackWith3Shelf5 from "../src/imgs/Daraz/Cabinets/027-Toy Rack With 3 Shelf/5.jpg";
import ToyRackWith5Shelf1 from "../src/imgs/Daraz/Cabinets/028 Toy Rack With 5 Shelf/1.jpg";
import ToyRackWith5Shelf2 from "../src/imgs/Daraz/Cabinets/028 Toy Rack With 5 Shelf/2.jpg";
import ToyRackWith5Shelf3 from "../src/imgs/Daraz/Cabinets/028 Toy Rack With 5 Shelf/3.jpg";
import ToyRackWith5Shelf4 from "../src/imgs/Daraz/Cabinets/028 Toy Rack With 5 Shelf/4.jpg";
import ToyRackWith5Shelf5 from "../src/imgs/Daraz/Cabinets/028 Toy Rack With 5 Shelf/5.jpg";
import StackingBookTable1 from "../src/imgs/Daraz/Cabinets/029 Stacking Book Table/1.jpg";
import StackingBookTable2 from "../src/imgs/Daraz/Cabinets/029 Stacking Book Table/2.webp";
import BookCaseWith2ShelfAndDrawers1 from "../src/imgs/Daraz/Cabinets/030- Book Case With 2 Shelf And Drawers/1.webp";
import BookCaseWith2ShelfAndDrawers2 from "../src/imgs/Daraz/Cabinets/030- Book Case With 2 Shelf And Drawers/2.webp";
import BookCaseWith2ShelfAndDrawers3 from "../src/imgs/Daraz/Cabinets/030- Book Case With 2 Shelf And Drawers/3.webp";
import BookCaseWith2ShelfAndDrawers4 from "../src/imgs/Daraz/Cabinets/030- Book Case With 2 Shelf And Drawers/4.webp";


export const tables = [
  
  
  // {
  //   id: 1,
  //   productName: "Study Table with Metal Base",
    
  //   price: null,
  //   productImage: studytablewithmetalbase1,
  //   images:[
  //     studytablewithmetalbase1,
  //     studytablewithmetalbase2,
  //     studytablewithmetalbase3,
  //     studytablewithmetalbase4,
  //   ],
  //   measurement:""
  // },
  // {
  //   id: 2,
  //   productName: "Minimal Study Table",
  //   slug:"",
  //   price: null,
  //   productImage: minimalstudytable1,
  //   images:[minimalstudytable1,
  //     minimalstudytable2,
  //     minimalstudytable3,
  //     minimalstudytable4,],
  //   measurement:""
  // },
  // {
  //   id: 3,
  //   productName: "Study Desk",
  //   slug:"",
  //   price: 699.0,
  //   productImage: studydesk2,
  //   images:[
  //     studydesk1,
  //     studydesk2,
  //     studydesk3,
  //     studydesk4,
  //     studydesk5,
  //   ],
  //   measurement:""
  // },
  {
    id: 4,
    productName: "Study Table With Side Cabinet",
    slug:"",
    price: 16000,
    productImage: studydeskwithdrawer1,
    images:[
      studydeskwithdrawer1,
      
      studydeskwithdrawer4,
    ],
    measurement:"120cm X 45cm X 72cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Introducing the Study Table With Side Cabinet, a versatile addition to your home office furniture collection. This desk offers ample workspace for studying, working, or organizing your tasks. The integrated side cabinet provides convenient storage for books, stationery, and other essentials, keeping your workspace tidy and organized. Crafted with quality materials, this study table is designed to withstand daily use while complementing your home decor. Elevate your productivity and create a dedicated work area with this stylish and practical home office desk. ",
    link:"https://www.daraz.com.np/products/study-table-with-side-cabinet-i129192066-s1037485070.html?spm=a2a0e.searchlist.sku.7.4b091104mZJhrE&search=1"

  },
  // {
  //   id: 5,
  //   productName: "Corner Computer Desk",
  //   slug:"",
  //   price: 19.99,
  //   productImage: cornercomputerdesk1,
  //   images:[
  //     cornercomputerdesk1,
  //     cornercomputerdesk2,
  //     cornercomputerdesk3,
  //     cornercomputerdesk4,
  //     cornercomputerdesk5,
  //   ],
  //   measurement:""
  // },
  {
    id: 6,
    productName: "Study Table With Drawer",
    slug:"",
    price: 12000,
    productImage: studydeskanddrawer1,
    images:[
      studydeskanddrawer1,
      studydeskanddrawer2,
      studydeskanddrawer3,
    ],
    measurement:"100cm X 40cm X 80cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for Study Room, Bedroom",
    link:"https://www.daraz.com.np/products/study-table-with-drawer-i129187575-s1037209458.html?spm=a2a0e.searchlist.sku.10.4b091104V234PB&search=1"
  },
  {
    id: 7,
    productName: "Minimal Study Table",
    slug:"",
    price: 7000,
    productImage: minimallongstudytable1,
    images:[
      minimallongstudytable1,
      minimallongstudytable2,
      minimallongstudytable3,
      minimallongstudytable4,
      minimallongstudytable5,
      minimallongstudytable6
    ],
    measurement:"120cm X 45cm X 76cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"This Minimal Study Table is perfect for your home office needs. Measuring 120cm X 45cm X 76cm, it provides ample space for your work essentials. The sleek and minimal design adds a touch of sophistication to your workspace. Made with high-quality materials, this study table is durable and built to last. Its compact size makes it ideal for small spaces. Whether you're working from home or studying, this study table is a must-have. ",
    link:"https://www.daraz.com.np/products/minimal-study-table-120cm-x-45cm-x-76cm-i129186651-s1037232025.html?spm=a2a0e.searchlist.sku.4.4b091104V234PB&search=1"
  },
  {
    id: 8,
    productName: "Study Table With Shelf",
    slug:"",
    price: 16000,
    productImage: studytablewith2sidedshelf1,
    images:[
      studytablewith2sidedshelf1,
      studytablewith2sidedshelf2,
      studytablewith2sidedshelf3,
      studytablewith2sidedshelf4,
      studytablewith2sidedshelf5,
      studytablewith2sidedshelf6,

    ],
     measurement:" 140cm X 60cm X 75cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for Study Room, Bedroom ",
    link:"https://www.daraz.com.np/products/study-table-with-shelf-140cm-x-60cm-x-75cm-i129184000-s1037213205.html?spm=a2a0e.searchlist.sku.8.4b0911044q1AGS&search=1"
  },
];

export const shelves= [
  
  
  {
    id: 1,
    productName: "Wall Display Shelves",
    slug:"",
    price: 5000,
    productImage: walldisplayshelf1,
    images:[
      walldisplayshelf1,
      walldisplayshelf2,
      walldisplayshelf3,
      walldisplayshelf4,
      walldisplayshelf5,
    ],
     measurement:"90cm X 60cm X 15cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Suitable for displaying small books, gift items, decors",
    link:"https://www.daraz.com.np/products/wall-display-shelves-90cm-x-60cm-x-15cm-i128649922-s1036356449.html?spm=a2a0e.searchlist.sku.1.38fc4a7aaJZ4kq&search=1"
  },
  {
    id: 2,
    productName: "4 Pieces Wall Shelf",
    slug:"",
    price: 4500,
    productImage: fourpiecewallshelf1,
    images:[
      fourpiecewallshelf1,
      fourpiecewallshelf2,
      fourpiecewallshelf3,
    ],
     measurement:"100cm X 19.4cm X 53cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Suitable for displaying small books, gift items, decors ",
    link:"https://www.daraz.com.np/products/4-pieces-wall-shelf-i128658125-s1036365423.html?spm=a2a0e.searchlist.sku.18.38fc4a7azahCTE&search=1"
  },
  {
    id: 3,
    productName: "Modern Wall Shelf Single",
    slug:"",
    price: 900,
    productImage: straightfloatingshelf1,
    images:[
      straightfloatingshelf1,
      straightfloatingshelf2,
      straightfloatingshelf3,
    ],
     measurement:" 60cm X 15cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Great for living room, study room, children room",
    link:"https://www.daraz.com.np/products/modern-wall-shelf-single-i129063366-s1037061462.html?spm=a2a0e.searchlist.sku.29.38fc4a7azahCTE&search=1"
  },
  {
    id: 4,
    productName: "Two Tier Wall Shelf",
    slug:"",
    price: 2200,
    productImage: twotierfloatingshelf3,
    images:[
      twotierfloatingshelf1,
      twotierfloatingshelf2,
      twotierfloatingshelf3,
    ],
     measurement:" 90cm x20cm x23cm",
    Material: "Made of Mdf Board",
    Style: "Modern Contemporary",
    desc:"Great for living room, study room, children room",
    link:"https://www.daraz.com.np/products/two-tier-wall-shelf-i129072080-s1037061468.html?spm=a2a0e.searchlist.sku.23.38fc4a7a41kORB&search=1"
  },
  {
    id: 5,
    productName: "Rectangle Wall Shelves Set Of Four",
    slug:"",
    price: 7000,
    productImage: rectanglefloatingshelf1,
    images:[
      rectanglefloatingshelf1,
      rectanglefloatingshelf2,
      rectanglefloatingshelf3,
      rectanglefloatingshelf4,
      rectanglefloatingshelf5,
    ],
     measurement:"24 X 6cm X 9cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for showcasing decor items, vases, gifts",
    link:"https://www.daraz.com.np/products/rectangle-wall-shelves-set-of-four-i129063490-s1037060717.html?spm=a2a0e.searchlist.sku.13.38fc4a7azahCTE&search=1"
  },
  {
    id: 6,
    productName: "3 Piece Floating Shelf",
    slug:"",
    price: 3500,
    productImage: upsidedownfloatingshelf1,
    images:[
      upsidedownfloatingshelf1,
      upsidedownfloatingshelf2,
      upsidedownfloatingshelf3,
      upsidedownfloatingshelf4,
      upsidedownfloatingshelf5,

    ],
     measurement:"91.5cm X 22 cm X 27 cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for showcasing decor items, vases, gifts",
    link:"https://www.daraz.com.np/products/3-piece-floating-shelf-i129061630-s1037060722.html?spm=a2a0e.searchlist.sku.33.38fc4a7azahCTE&search=1"
  },
  {
    id: 7,
    productName: "Modern Wall Shelf For Décor Items",
    slug:"",
    price: 3500,
    productImage: inclinedfshelf1,
    images:[
      inclinedfshelf1,
      inclinedfshelf2,
      inclinedfshelf3,
      inclinedfshelf4,
     
    ],
     measurement:"90cm x 18cm x 35cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for decor and gift items",
    link:"https://www.daraz.com.np/products/modern-wall-shelf-for-decor-items-i129072115-s1037059652.html?spm=a2a0e.searchlist.sku.34.38fc4a7azahCTE&search=1"
  },
  {
    id: 8,
    productName: "Wall Shelf Laminated For Décor Items",
    slug:"",
    price: 1000,
    productImage: floatingshelf1,
    images:[
      floatingshelf1,
      floatingshelf2,
      floatingshelf3,
     

    ],
     measurement:"2ft x 8inch x 8inch",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for displaying home décor items ",
    link:"https://www.daraz.com.np/products/wall-shelf-laminated-for-decor-items-i129062566-s1037059686.html?spm=a2a0e.searchlist.sku.24.38fc4a7azahCTE&search=1"
  },
  
  {
    id: 9,
    productName: "Kitchen Rack For Small Items Cups Plates Jars",
    slug:"",
    price: 4000,
    productImage: kitchenshelfwithhooks1,
    images:[
      kitchenshelfwithhooks1,
      kitchenshelfwithhooks2,
      kitchenshelfwithhooks3,
      kitchenshelfwithhooks4,
    
    ],
     measurement:"85cm x 15cm x 65cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Ideal for small kitchens and limited spaces.",
    link:"https://www.daraz.com.np/products/kitchen-rack-for-small-items-cups-plates-jars-i129078328-s1037078398.html?spm=a2a0e.searchlist.sku.1.4db5370fTqsXmE&search=1"
  },
  {
    id: 10,
    productName: "3 Tier Kitchen Shelf",
    slug:"",
    price: null,
    productImage: threetierkitchenshelf1,
    images:[
      threetierkitchenshelf1,
      threetierkitchenshelf2,
      threetierkitchenshelf3,
      threetierkitchenshelf4,
      threetierkitchenshelf5,
      threetierkitchenshelf6,
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 11,
    productName: "Wall Display Shelves",
    slug:"",
    price: 5000,
    productImage: eightpiecefloatingshelf1,
    images:[
      eightpiecefloatingshelf1,
      eightpiecefloatingshelf2,
      eightpiecefloatingshelf3,
      eightpiecefloatingshelf4,
      eightpiecefloatingshelf5,
      
    ],
     measurement:"120cm X 45cm X 72cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Suitable for displaying small books, gift items, decors",
    link:"https://www.daraz.com.np/products/wall-display-shelves-90cm-x-60cm-x-15cm-i128649922-s1036356449.html?spm=a2a0e.searchlist.sku.1.38fc4a7azahCTE&search=1"
  },
  {
    id: 12,
    productName: "Stylish Modern Wall Shelf Unit For Decors",
    slug:"",
    price: 5000,
    productImage: carmelafloatingshelf1,
    images:[
      carmelafloatingshelf1,
      carmelafloatingshelf2,
      carmelafloatingshelf3,
      
    ],
     measurement:"48in x 24in x 9in",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Provides ample storage space for books and decor",
    link:"https://www.daraz.com.np/products/stylish-modern-wall-shelf-unit-for-decors-i129076548-s1037078399.html?spm=a2a0e.searchlist.sku.8.38fc4a7azahCTE&search=1"
  },
  {
    id: 13,
    productName: "Laminated Floating Shelf",
    slug:"",
    price: 3000,
    productImage: threepiecefloatingshelf3,
    images:[
      threepiecefloatingshelf1,
      threepiecefloatingshelf2,
      threepiecefloatingshelf3,
    ],
     measurement:"75 cm x 18 cm x 18 cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Provides ample storage space for books and decor ",
    link:"https://www.daraz.com.np/products/laminated-floating-shelf-i129077498-s1037072860.html?spm=a2a0e.searchlist.sku.10.38fc4a7azahCTE&search=1"
  },
  {
    id: 14,
    productName: "2 Piece Floating Shelf",
    slug:"",  
    price: 4800,
    productImage: openfloatingshelf4,
    images:[
      openfloatingshelf1,
      openfloatingshelf2,
      openfloatingshelf3,
      openfloatingshelf4,
      
    ],
     measurement:"105 cm x 18 cm x 20 cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for living room furniture",
    link:"https://www.daraz.com.np/products/2-piece-floating-shelf-i129077504-s1037077640.html?spm=a2a0e.searchlist.sku.20.38fc4a7azahCTE&search=1"
  },
  {
    id: 15,
    productName: "Floating Book & Décor Shelf Or TV Console",
    slug:"",
    price: 5600,
    productImage: wallshelf4piece2,
    images:[
      wallshelf4piece1,
      wallshelf4piece2,
      wallshelf4piece3,
    
    ],
     measurement:"105cm x 22cm x 35cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for living room, bedrooms",
    link:"https://www.daraz.com.np/products/floating-book-decor-shelf-or-tv-console-i129081088-s1037070979.html?spm=a2a0e.searchlist.sku.2.38fc4a7azahCTE&search=1"
  },
  {
    id: 16,
    productName: "4 Step Floating Shelf",
    slug:"",
    price: 4500,
    productImage: bassetfloatingshelf1,
    images:[
      bassetfloatingshelf1,
      bassetfloatingshelf2,
      bassetfloatingshelf3,
      

    ],
     measurement:"48in x 6in x 7in",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for living room furniture Provides ample storage space for books and décor",
    link:"https://www.daraz.com.np/products/4-step-floating-shelf-i129080323-s1037078561.html?spm=a2a0e.searchlist.sku.12.38fc4a7azahCTE&search=1"
  },
  {
    id: 17,
    productName: "5 Step Laminated Shelf",
    slug:"",
    price: 7000,
    productImage: diezelfloatingshelf1,
    images:[
      diezelfloatingshelf1,
      diezelfloatingshelf2,
      diezelfloatingshelf3,
      

    ],
     measurement:" 36in x 7in x 60in",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for living room and bedroom furniture",
    link:"https://www.daraz.com.np/products/5-step-laminated-shelf-i129077543-s1037072945.html?spm=a2a0e.searchlist.sku.19.38fc4a7azahCTE&search=1"
  },
  {
    id: 18,
    productName: "Accent Shelf Décor With Laminated Top",
    slug:"",
    price: 5000,
    productImage: cornor5pieceaccentshelf2,
    images:[
      cornor5pieceaccentshelf1,
      cornor5pieceaccentshelf2,
      

    ],
     measurement:"48in x 8in x 14in",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Provides ample storage space for books and décor",
    link:"https://www.daraz.com.np/products/accent-shelf-decor-with-laminated-top-i129076677-s1037077658.html?spm=a2a0e.searchlist.sku.22.38fc4a7anFT9eO&search=1"
  },
  {
    id: 19,
    productName: "Floating 3 Piece Shelf",
    slug:"",
    price: 3200,
    productImage: threesquarefloatingshelf1,
    images:[
      threesquarefloatingshelf1,
      
    ],
     measurement:"105 cm x 22 cm x 43 cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for living room, bedrooms",
    link:"https://www.daraz.com.np/products/floating-3-piece-shelf-i129078488-s1037070977.html?spm=a2a0e.searchlist.sku.17.38fc4a7azahCTE&search=1"
  },
  {
    id: 20,
    productName: "4 Step Accent Shelf",
    slug:"",
    price: 12000,
    productImage: threetierwallshelf2,
    images:[
      threetierwallshelf1,
      threetierwallshelf2,
      
    ],
     measurement:"120cm x 29 cm x 89cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for living room, bedrooms Pre-assembled",
    link:"https://www.daraz.com.np/products/4-step-accent-shelf-i129077557-s1037081182.html?spm=a2a0e.searchlist.sku.15.38fc4a7azahCTE&search=1"
  },
  {
    id: 21,
    productName: "Floating Book & Décor Shelf Or TV Console",
    slug:"",
    price: 5600,
    productImage: jabare5piecefloatingshelf1,
    images:[
      jabare5piecefloatingshelf1,
      jabare5piecefloatingshelf2,
      jabare5piecefloatingshelf3,
     
    ],
     measurement:"105cm x 22cm x 35cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/floating-book-decor-shelf-or-tv-console-i129081088-s1037070979.html?spm=a2a0e.searchlist.sku.2.38fc4a7azahCTE&search=1"
  },
  {
    id: 22,
    productName: "Contemporary Floating Shelf",
    slug:"",
    price: 3000,
    productImage: ledgewallshelf1,
    images:[
      ledgewallshelf1,
      ledgewallshelf2,
      ledgewallshelf3,
      

    ],
     measurement:"48inch x 8inch x 14inch",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/contemporary-floating-shelf-i129081089-s1037078638.html?spm=a2a0e.searchlist.sku.11.38fc4a7azahCTE&search=1"
  },
  {
    id: 23,
    productName: "Laminated Floating Shelf",
    slug:"",
    price: 3500,
    productImage: twotierbookshelf1,
    images:[
      twotierbookshelf1,
      twotierbookshelf2,
      
    ],
     measurement:"100cm x 20cm x 30cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/laminated-floating-shelf-100cm-x-20-cm-x-30cm-i129079455-s1037078639.html?spm=a2a0e.searchlist.sku.7.38fc4a7azahCTE&search=1"
  },
  {
    id: 24,
    productName: "Ladder Book Shelf",
    slug:"",
    price: null,
    productImage: ladderbookshelf1,
    images:[
      ladderbookshelf1,
      ladderbookshelf2,
      ladderbookshelf3,
      ladderbookshelf4,
      ladderbookshelf5,
      
    ],
     measurement:"",
    Material: "",
    Style: "",
    desc:"",
    link:""
  },
  {
    id: 25,
    productName: "Standing Book Display Rack",
    slug:"",
    price: 6500,
    productImage: standingbookdisplayrack1,
    images:[
      standingbookdisplayrack1,
      standingbookdisplayrack2,
      standingbookdisplayrack3,
      standingbookdisplayrack4,
      standingbookdisplayrack5,
      

    ],
     measurement:" 150 cm x 45 cm x 22 cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/standing-book-display-rack-i129077567-s1037070994.html?spm=a2a0e.8553159.0.0.2c3054de0ZdCXb&search=store&mp=3"
  },
  {
    id: 26,
    productName: "4 Square Book Shelf Unit",
    slug:"",
    price: 5500,
    productImage: fourshelfbookrack2,
    images:[
      fourshelfbookrack1,
      fourshelfbookrack2,
      

    ],
     measurement:"120cm X 45cm X 72cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Introducing the Study Table With Side Cabinet, a versatile addition to your home office furniture collection. This desk offers ample workspace for studying, working, or organizing your tasks. The integrated side cabinet provides convenient storage for books, stationery, and other essentials, keeping your workspace tidy and organized. Crafted with quality materials, this study table is designed to withstand daily use while complementing your home decor. Elevate your productivity and create a dedicated work area with this stylish and practical home office desk. ",
    link:""
  },
  {
    id: 27,
    productName: "Round Corner Book Shelf",
    slug:"",
    price: null,
    productImage: roundcornorbookshelf1,
    images:[
      roundcornorbookshelf1,
      roundcornorbookshelf2,
      roundcornorbookshelf3,
      roundcornorbookshelf4,
      roundcornorbookshelf5,
      roundcornorbookshelf6,
      
    ],
     measurement:"",
    Material: "",
    Style: "",
    desc:"",
    link:""
  },
  {
    id: 28,
    productName: "Six Shelf Book Rack",
    slug:"",
    price: null,
    productImage: sixshelfbookrack1,
    images:[
      sixshelfbookrack1,
    
    ],
     measurement:"",
    Material: "",
    Style: "",
    desc:"",
    link:""
  },
  {
    id: 29,
    productName: "4 Step Book Shelf",
    slug:"",
    price: 6000,
    productImage: fourtierbookshelf1,
    images:[
      fourtierbookshelf1,
      
    ],
     measurement:"30cm X 23.5cm X 106cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/4-step-book-shelf-i129192182-s1037205855.html?spm=a2a0e.searchlist.sku.3.38fc4a7azahCTE&search=1"
  },
  {
    id: 30,
    productName: "9 Square Book Shelf",
    slug:"",
    price: 15000,
    productImage: squarebookshelf6,
    images:[
      squarebookshelf1,
      squarebookshelf2,
      squarebookshelf3,
      squarebookshelf4,
      squarebookshelf5,
      squarebookshelf6,
     
    ],
     measurement:"90cm X 29.5cm X 90Cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/9-square-book-shelf-i129191248-s1037208572.html?spm=a2a0e.searchlist.sku.6.38fc4a7azahCTE&search=1"
  },
];

export const cabinets = [
  
  
  {
    id: 1,
    productName: "Shoe Rack & Book Cabinet & Sitting Bench",
    slug:"",
    price: 13000,
    productImage: UpholsteredShoeBench1,
    images:[
      UpholsteredShoeBench1,
      UpholsteredShoeBench2,
      UpholsteredShoeBench3,
      UpholsteredShoeBench4,
      UpholsteredShoeBench5,
      UpholsteredShoeBench6,
      
    ],
     measurement:" 104cm X 30cm x 48cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture and Color may vary according to lot",
    link:"https://www.daraz.com.np/products/shoe-rack-book-cabinet-sitting-bench-i129223736-s1037248751.html?spm=a2a0e.searchlist.sku.7.75ed59aenx72i0&search=1"
  },
  {
    id: 2,
    productName: "Upholstered Storage Bench",
    slug:"",
    price: null,
    productImage: UpholsteredStorageBench1,
    images:[
      UpholsteredStorageBench1,
      UpholsteredStorageBench2,
      UpholsteredStorageBench3,
      UpholsteredStorageBench4,
      UpholsteredStorageBench5,
      
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 3,
    productName: "Shoe Cabinet Closed and Open",
    slug:"",
    price: 18000,
    productImage: ShoeStorageCabinet1,
    images:[
      ShoeStorageCabinet1,
      ShoeStorageCabinet2,
      ShoeStorageCabinet3,
      ShoeStorageCabinet4,
      
    ],
     measurement:"80cm X 30cm X 80cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/shoe-cabinet-closed-and-open-i129224849-s1037249590.html?spm=a2a0e.searchlist.sku.4.75ed59aenx72i0&search=1"
  },
  {
    id: 4,
    productName: "Shoe Cabinet With Shutter",
    slug:"",
    price: null,
    productImage: ShoeCabinetWithShutter1,
    images:[
      ShoeCabinetWithShutter1,
      ShoeCabinetWithShutter2,
      ShoeCabinetWithShutter3,
      ShoeCabinetWithShutter4,
      
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 5,
    productName: "Meeting Table",
    slug:"",
    price: null,
    productImage: MeetingTable1,
    images:[
      MeetingTable1,
      MeetingTable2,
      MeetingTable3,
      MeetingTable4,
  
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 6,
    productName: "Chest of Drawers - 3 Drawers",
    slug:"",
    price: 12000,
    productImage: ThreeTierChestOfDrawers5,
    images:[
      ThreeTierChestOfDrawers1,
      ThreeTierChestOfDrawers2,
      ThreeTierChestOfDrawers3,
      ThreeTierChestOfDrawers4,
      ThreeTierChestOfDrawers5,
      
    ],
     measurement:" 40cm X 36cm X 56cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/chest-of-drawers-3-drawers-i129224957-s1037253099.html?spm=a2a0e.searchlist.sku.3.24377293XAx3bv&search=1"
  },
  {
    id: 7,
    productName: "Chest of Drawers - 5 Drawers",
    slug:"",
    price: 18000,
    productImage: TierChestOfDrawers1,
    images:[
      TierChestOfDrawers1,
      TierChestOfDrawers2,
      TierChestOfDrawers3,
      TierChestOfDrawers4,
      
    ],
     measurement:"50cm X 40cm X 93cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot ",
    link:"https://www.daraz.com.np/products/chest-of-drawers-5-drawers-i129227851-s1037246896.html?spm=a2a0e.searchlist.sku.1.24377293XAx3bv&search=1"
  },
  {
    id: 8,
    productName: "Handleless Chest of Drawers",
    slug:"",
    price: 12000,
    productImage: HandlelesschestofDrawers3,
    images:[
      HandlelesschestofDrawers1,
      HandlelesschestofDrawers2,
      HandlelesschestofDrawers3,
      
    ],
     measurement:"70cm X 40cm X 77cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/handleless-chest-of-drawers-i129233466-s1037256191.html?spm=a2a0e.searchlist.sku.2.24377293XAx3bv&search=1"
  },
  
  {
    id: 9,
    productName: "Book Case With 5 Shelf And Drawers",
    slug:"",
    price: null,
    productImage: BookCaseWith5ShelfAndDrawers1,
    images:[
      BookCaseWith5ShelfAndDrawers1,
      BookCaseWith5ShelfAndDrawers2,
      BookCaseWith5ShelfAndDrawers3,
      
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 10,
    productName: "Tv Stand for Tv's Upto 65 Inches",
    slug:"",
    price: 18500,
    productImage: TVConsole6feet1,
    images:[
      TVConsole6feet1,
      TVConsole6feet2,
      TVConsole6feet3,
      TVConsole6feet4,
      TVConsole6feet5 ],
      measurement:"180cm X 31.5cm X 40cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/tv-stand-for-tvs-upto-65-inches-i129237217-s1037251609.html?spm=a2a0e.8553159.0.0.4d2b54decqejGe&search=store&mp=3"
    
  },
  {
    id: 11,
    productName: "Tv Console",
    slug:"",
    price: 13000,
    productImage: TwoShelfandDrawersTVconsole6,
    images:[
      TwoShelfandDrawersTVconsole1,
      TwoShelfandDrawersTVconsole2,
      TwoShelfandDrawersTVconsole3,
      TwoShelfandDrawersTVconsole4,
      TwoShelfandDrawersTVconsole5,
      TwoShelfandDrawersTVconsole6,
      
    ],
     measurement:" 135cm X 40cm X 49.5cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/tv-console-i129234515-s1037251646.html?spm=a2a0e.searchlist.sku.35.d1a145adwHb1hU&search=1"
  },
  {
    id: 12,
    productName: "TV Console Upto 55 Inch TV Engineered Wood",
    slug:"",
    price: 15000,
    productImage: ThreeShelfandDrawerTvConsole3,
    images:[
      ThreeShelfandDrawerTvConsole1,
      ThreeShelfandDrawerTvConsole2,
      ThreeShelfandDrawerTvConsole3,
      ThreeShelfandDrawerTvConsole4,
      ThreeShelfandDrawerTvConsole5,
      ThreeShelfandDrawerTvConsole6,
      
    ],
     measurement:" 60 inch x 12 inch x 13.5 inch",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Leg finish can vary ",
    link:"https://www.daraz.com.np/products/tv-console-upto-55-inch-tv-engineered-wood-i129823886-s1037670464.html?spm=a2a0e.searchlist.sku.28.38fc4a7aQgHRln&search=1"
  },
  {
    id: 13,
    productName: "3 Step Book Shelf",
    slug:"",
    price: 10000,
    productImage: StepupBookCase1,
    images:[
      StepupBookCase1,
      StepupBookCase2,
      StepupBookCase3,
     

    ],
     measurement:"96.5cm x 29cm x 96.5cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color : Wood Texture may vary according to lot",
    link:"https://www.daraz.com.np/products/3-step-book-shelf-i129530309-s1037477672.html?spm=a2a0e.searchlist.sku.9.38fc4a7aQgHRln&search=1"
  },
  {
    id: 14,
    productName: "Rectangle Shelf",
    slug:"",  
    price: null,
    productImage: RectangleShelf2,
    images:[
      RectangleShelf1,
      RectangleShelf2,
      RectangleShelf3,
      
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 15,
    productName: "9 Square Book Shelf",
    slug:"",
    price: 15500,
    productImage: squarebookshelf5,
    images:[
      squarebookshelf1,
      squarebookshelf2,
      squarebookshelf3,
      squarebookshelf4,
      squarebookshelf5,
      
    ],
    measurement:"90cm X 29.5cm X 90cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/9-square-book-shelf-i129191248-s1037208572.html?spm=a2a0e.searchlist.sku.6.38fc4a7aQgHRln&search=1"
  },
  {
    id: 16,
    productName: "Book Rack With A Drawer",
    slug:"",
    price: 15500,
    productImage: FourTierOpenandClose3,
    images:[
      FourTierOpenandClose1,
      FourTierOpenandClose2,
      FourTierOpenandClose3,
      
    ],
     measurement:"2inchs x 14inchs x 4inchs",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for any room, bedroom, office, study room",
    link:"https://www.daraz.com.np/products/book-rack-with-a-drawer-i121742653-s1033117315.html?spm=a2a0e.searchlist.sku.19.4d5945adc2YqB5&search=1"
  },
  {
    id: 17,
    productName: "3 Tier Wall Shelf",
    slug:"",
    price: 3000,
    productImage: CakeShelf3,
    images:[
      CakeShelf1,
      CakeShelf2,
      CakeShelf3,
      
    ],
     measurement:"90CM X 20CM X 45CM",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/3-tier-wall-shelf-i129530330-s1037481810.html?spm=a2a0e.searchlist.sku.14.38fc4a7adKXjQg&search=1"
  },
  {
    id: 18,
    productName: "4 Tier Drawers",
    slug:"",
    price: null,
    productImage: TierDrawers2,
    images:[
      TierDrawers1,
      TierDrawers2,
      TierDrawers3,
      TierDrawers4,
      
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 19,
    productName: "Cube Bookcase",
    slug:"",
    price: null,
    productImage: CubeBookcase5,
    images:[
      CubeBookcase1,
      CubeBookcase2,
      CubeBookcase3,
      CubeBookcase4,
      CubeBookcase5,
      
    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:" ",
    link:""
  },
  {
    id: 20,
    productName: "Laminated Floating Shelf",
    slug:"",
    price: 3500,
    productImage: twotierbookshelf1,
    images:[
      twotierbookshelf1,
      twotierbookshelf2,
      
    ],
     measurement:"100cm x 20cm x 30cm",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Color: Wood texture- may vary according to lot",
    link:"https://www.daraz.com.np/products/laminated-floating-shelf-100cm-x-20-cm-x-30cm-i129079455-s1037078639.html?spm=a2a0e.searchlist.sku.7.38fc4a7adKXjQg&search=1"
  },
  {
    id: 21,
    productName: "TV Console Upto 55 Inch TV Engineered Wood",
    slug:"",
    price: 16000,
    productImage: TVStandwithlegs2,
    images:[
      TVStandwithlegs1,
      TVStandwithlegs2,
      TVStandwithlegs3,
      
    ],
     measurement:" 60 inch x 12 inch x 13.5 inch",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Leg size - 6 inch height Leg finish can vary",
    link:"https://www.daraz.com.np/products/tv-console-upto-55-inch-tv-engineered-wood-i129823886-s1037670464.html?spm=a2a0e.searchlist.sku.37.d1a145adcwSP1z&search=1"
  },
  {
    id: 22,
    productName: "2 Side Closed TV Console Unit",
    slug:"",
    price: 14000,
    productImage: TVStandwithHandlessDrawers1,
    images:[
      TVStandwithHandlessDrawers1,
      TVStandwithHandlessDrawers2,
      TVStandwithHandlessDrawers3,


    ],
     measurement:"59.1 inch x 11.8 inch x 15.7 inch",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Legs may or may not be assembled.",
    link:"https://www.daraz.com.np/products/2-side-closed-tv-console-unit-i129828838-s1037680263.html?spm=a2a0e.searchlist.sku.1.d1a145adcwSP1z&search=1"
  },

  {
    id: 24,
    productName: "Tv Unit Display Cabinet Storage Rack",
    slug:"",
    price: 7500,
    productImage: LshapeTVconsole4,
    images:[
      LshapeTVconsole1,
      LshapeTVconsole2,
      LshapeTVconsole3,
      LshapeTVconsole4,
   
    ],
     measurement:" 4ft X 2ft X 1ft",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Perfect for living room, bedrooms",
    link:"https://www.daraz.com.np/products/tv-unit-display-cabinet-storage-rack-i128647743-s1036352673.html?spm=a2a0e.searchlist.sku.2.d1a145adcwSP1z&search=1"
  },
  {
    id: 25,
    productName: "Open And Close TV Console",
    slug:"",
    price: 16000,
    productImage: OpenAndCloseTVconsole4,
    images:[
      OpenAndCloseTVconsole1,
      OpenAndCloseTVconsole2,
      OpenAndCloseTVconsole3,
      OpenAndCloseTVconsole4,
      OpenAndCloseTVconsole5,
      OpenAndCloseTVconsole6,
      OpenAndCloseTVconsole7,
     
    ],
     measurement:"63 inch x 13.8 inch x 21.7 inch ",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"Legs may or may not be assembled",
    link:"https://www.daraz.com.np/products/open-and-close-tv-console-i129835787-s1037680242.html?spm=a2a0e.searchlist.sku.33.f62a45adewznTK&search=1"
  },
  {
    id: 26,
    productName: "TV Console With Open And Close Tier",
    slug:"",
    price: 8000,
    productImage: TVconsole2Tier1,
    images:[
      TVconsole2Tier1,
      TVconsole2Tier2,
      TVconsole2Tier3,
      

    ],
     measurement:"48 inch x 12 inch x 12 inch",
    Material: "Engineered Wood Materia",
    Style: "Modern Contemporary",
    desc:"Oak texture finish",
    link:"https://www.daraz.com.np/products/tv-console-with-open-and-close-tier-i129828849-s1037681277.html?spm=a2a0e.searchlist.sku.17.4d5945adWdgChP&search=1"
  },
  {
    id: 27,
    productName: "Toy Shelf And Organizer",
    slug:"",
    price: 9000,
    productImage: ToyRackWith3Shelf5,
    images:[
      ToyRackWith3Shelf1,
      ToyRackWith3Shelf2,
      ToyRackWith3Shelf3,
      ToyRackWith3Shelf4,
      ToyRackWith3Shelf5,
      
    ],
     measurement:"23.6 inch x 13.3 inch x 29.1 inch",
    Material: "Engineered Wood Material",
    Style: "Modern Contemporary",
    desc:"Leg size - 2 inch height ",
    link:"https://www.daraz.com.np/products/toy-shelf-and-organizer-i129823950-s1037681278.html?spm=a2a0e.searchlist.sku.16.4d5945adD5fxmF&search=1"
  },
  {
    id: 28,
    productName: "Toy Shelf and Organizer For Toys",
    slug:"",
    price: 12000,
    productImage: ToyRackWith5Shelf5,
    images:[
      ToyRackWith5Shelf1,
      ToyRackWith5Shelf2,
      ToyRackWith5Shelf3,
      ToyRackWith5Shelf4,
      ToyRackWith5Shelf5,
      
    ],
     measurement:"35.1 inch x 13.8 inch x 29.1 inch",
    Material: "Engineered Wood Material",
    Style: "Modern Contemporary",
    desc:"Legs may or may not be assembled",
    link:"https://www.daraz.com.np/products/toy-shelf-and-organizer-for-toys-i129833845-s1037673349.html?spm=a2a0e.searchlist.sku.8.4d5945adD5fxmF&search=1"
  },
  {
    id: 29,
    productName: "Stacking Book Table",
    slug:"",
    price: null,
    productImage: StackingBookTable1,
    images:[
      StackingBookTable1,
      StackingBookTable2,
     

    ],
     measurement:"",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"",
    link:""
  },
  {
    id: 30,
    productName: "Book Rack With A Drawer",
    slug:"",
    price: 15000,
    productImage: BookCaseWith2ShelfAndDrawers4,
    images:[
      BookCaseWith2ShelfAndDrawers1,
      BookCaseWith2ShelfAndDrawers2,
      BookCaseWith2ShelfAndDrawers3,
      BookCaseWith2ShelfAndDrawers4,
      
    ],
     measurement:"2inchs x 14inchs x 4inchs",
    Material: "Laminated MDF",
    Style: "Modern Contemporary",
    desc:"One Drawer. Perfect for any room, bedroom, office, study room",
    link:"https://www.daraz.com.np/products/book-rack-with-a-drawer-i121742653-s1033117315.html?spm=a2a0e.searchlist.sku.19.4d5945adj082jZ&search=1"
  },
];

