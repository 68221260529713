import React, { useState } from 'react'
import MetaData from "../../MetaData";
import "./contact.css"
import { FaArrowDownLong } from "react-icons/fa6";
import { toast } from 'react-toastify';
import logo from '../../imgs/logo.svg'
import { FaLocationDot } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Button } from 'antd';

function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [desc, setDesc] = useState("")


  const handleContactSubmit = async () => {
    // Basic validation
    // if (!name || !email || !desc) {
    //   toast.error('Please fill in all fields.');
    //   return;
    // }
  
    // // Email validation using a regular expression
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //   toast.error('Please enter a valid email address.');
    //   return;
    // }
  
    // try {
    //   const messageSend = await sendMessage({ name, email, desc });
    //   setName("")
    //   setEmail("")
    //   setDesc("")
    //   toast.success('Message sent successfully!');
    // } catch (error) {
    //   console.error('Error sending message:', error);
    //   toast.error('Error sending message. Please try again.');
    // }
  };

  return (
    <>
    <MetaData title="Contact Us" />
    <div className='contact-container'>
        <div className="booking-title">
        <div class="text-boxx">
		<h1>Contact Us</h1>
		<h1>Contact Us</h1>
	</div>
        </div>
        <div className="contact-middle">
            <div className="contact-middle-left">
              <h1> 𝙻𝚎𝚊𝚟𝚎 𝚄𝚜 𝚊 𝙼𝚎𝚜𝚜𝚊𝚐𝚎  </h1>
              <p>𝙵𝚎𝚎𝚕 𝚏𝚛𝚎𝚎 𝚝𝚘 𝚛𝚎𝚊𝚌𝚑 𝚘𝚞𝚝 𝚝𝚘 𝚞𝚜 𝚏𝚘𝚛 𝚒𝚗𝚚𝚞𝚒𝚛𝚒𝚎𝚜, 𝚌𝚘𝚕𝚕𝚊𝚋𝚘𝚛𝚊𝚝𝚒𝚘𝚗𝚜, 𝚘𝚛 𝚓𝚞𝚜𝚝 𝚝𝚘 𝚜𝚊𝚢 𝚑𝚎𝚕𝚕𝚘 – 𝚠𝚎'𝚍 𝚕𝚘𝚟𝚎 𝚝𝚘 𝚑𝚎𝚊𝚛 𝚏𝚛𝚘𝚖 𝚢𝚘𝚞!</p>
              <div className="contact-icons">
                <div className="contact-icon-deco">
                  <FaLocationDot className='actual-icon'/>
                </div>
                <p className='icon-desc'>𝚂𝚊𝚗𝚘𝚐𝚊𝚞𝚗, 𝙻𝚊𝚕𝚒𝚝𝚙𝚞𝚛, 𝙽𝚎𝚙𝚊𝚕</p>
              </div>
              <div className="contact-icons">
                <div className="contact-icon-deco">
                  <BsFillTelephoneFill className='actual-icon'/>
                </div>
                <p className='icon-desc' style={{letterSpacing:"5px"}}>+977-9801912249/ 9801912256/ 9801912257/ 9801912258</p>
                
              </div>
             
            </div>
            <div className="contact-middle-right">
            <div class="text-boxx" style={{textAlign:"left"}}>
		<h1 style={{ fontSize:"5rem" , letterSpacing:"5px"}}>Visit Us</h1>
		<h1 style={{fontSize:"5rem" , letterSpacing:"5px"}}>Visit Us</h1>
	</div>
  <FaArrowDownLong className='project-display-model-arrow' style={{ width:"3rem" , height:"3rem"}}/>
            </div>
        </div>
        <div style={{ width: '100%' }} className='contact-map'>
          <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Urban%20Furniture%20Industry+(My%20Business%20Name)&amp;t=k&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.maps.ie/population/">Calculate population in area</a>
          </iframe>
        </div>
    </div>
    </>
  )
}

export default Contact