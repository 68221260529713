import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion ,  useAnimation} from "framer-motion";
import MetaData from "../../MetaData";
import { useInView } from "react-intersection-observer";
import { AiOutlineEnter } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import urban from '../../../src/imgs/Urban.svg'
import logo from '../../../src/imgs/logo.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import furniture from '../../../src/imgs/furniture.svg'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FreeMode, Navigation, Thumbs ,Autoplay ,Pagination,EffectFade} from 'swiper/modules';
import table from '../../../src/imgs/table.jpeg'
import shelf from '../../../src/imgs/shelfmodel.jpeg'
import homeimg1 from '../../../src/imgs/homepageimg/3.jpg'
import homeimg2 from '../../../src/imgs/homepageimg/1.jpg'
import homeimg3 from '../../../src/imgs/homepageimg/5.jpg'
import homeimg4 from '../../../src/imgs/homepageimg/11.jpg'
import homeimg5 from '../../../src/imgs/homepageimg/12.jpg'
import cabinet from '../../../src/imgs/cabinet.jpeg'


import instaIcon from '../../assests/insta-icon.png'
import facebook from '../../assests/facebook.png'
import gmail from '../../assests/gmail.png'
import whatsapp from '../../assests/whatsapp.png'
import modern3 from '../../imgs/CNC JALI PARTITION/2.jpg';
import modern1 from '../../assests/renders/6.dhapa height/3.jpg'
import modern2 from '../../assests/renders/Construction Projects/6/1.jpg';

import { MdDesignServices } from "react-icons/md";
import { MdConstruction } from "react-icons/md";
import { SiCncf } from "react-icons/si";





import './home.css'

const categories = [
  'Modern',
  'Modern Classical',
  'Classical',
  'Neo classical',
  "Boho"
  
];

const Modern =[
  {
    title:"Budanilkantha-Residence" , 
    place:"Harisiddhi",
    image:homeimg1
  },
  {
    title:"Dhapa height-Residence" , 
    place:"Harisiddhi",
    image:homeimg2
  },
  {
    title:"Kausaltar - Residence" , 
    place:"Harisiddhi",
    image:homeimg3
  },
  {
    title:"Pepsicola- Residence" , 
    place:"Harisiddhi",
    image:homeimg4
  },
  {
    title:"Shoymbu- Residence" , 
    place:"Harisiddhi",
    image:homeimg5
  },

]




function Home({history}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeCategory, setActiveCategory] = useState(0);
  const [isCategoryClicked, setIsCategoryClicked] = useState(activeCategory === 0 ? true : false);
  const bottomRef = useRef(null);

  const { ref: frontTopRef, inView: frontTopInView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });
  console.log(categories)
  const handleCategoryClick = (e, index) => {
    e.preventDefault();
    setActiveCategory(index);
    setIsCategoryClicked(true);
  };

  

  const handleProjectDisplay = (title) => {

    const bookingRoute = `/products/${title}`;
   
    window.location.href = bookingRoute;

    
    
  };

  const handleProjectClick = (title) => {

    const bookingRoute = `/${title}`;
   
    window.location.href = bookingRoute;
    
  };
  
  const controls = useAnimation(); // <-- Moved outside of forEach
  const [textContent, setTextContent] = useState('Interior Design Studio');
  const [workContent, setWorkContent] = useState('Workshop');

// /𝖨𝗇𝗍𝖾𝗋𝗂𝗈𝗋 𝖣𝖾𝗌𝗂𝗀𝗇 𝖲𝗍𝗎𝖽𝗂𝗈 𝖶𝗈𝗋𝗄𝗌𝗁𝗈𝗉/ 

  const topItemRef = useRef();

  const containerVariants = {
    hidden: { },
    visible: {
      transition: {
        staggerChildren: 0.15 // Stagger the animation of children by 0.1 seconds
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1 // Duration of the fade-in animation
      }
    }
  };




  return (
    <>
    <MetaData title="Moonkiri Design Studio" />
    <div className="main-hero">
    
      <motion.div
        className="heptagon-div"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode,EffectFade, Navigation,Pagination, Thumbs , Autoplay]}
        className="mySwiper2"
      > 
        {
            Modern?.map(i =>(
                <SwiperSlide className='swiper-main-image'>
          <img src={i?.image} />
        </SwiperSlide>
            ))
        }
        
      </Swiper>
        {/* {Array.from({ length: 15 }).map((_, index) => (
          <motion.div
            key={index}
            className="home-page-heptagon"
            variants={itemVariants}
          />
        ))} */}
      </motion.div>
    <div className='home-container' id="home">
        <div className="front-page">
          <div className="front-top" >

            {/* <p>"𝙲𝚎𝚕𝚎𝚋𝚛𝚊𝚝𝚒𝚗𝚐 𝙴𝚟𝚎𝚛𝚢 𝙲𝚘𝚛𝚗𝚎𝚛, 𝙲𝚛𝚊𝚏𝚝𝚒𝚗𝚐 𝚃𝚒𝚖𝚎𝚕𝚎𝚜𝚜 𝙴𝚕𝚎𝚐𝚊𝚗𝚌𝚎: 𝚈𝚘𝚞𝚛 𝙹𝚘𝚞𝚛𝚗𝚎𝚢 𝚝𝚘 𝙸𝚗𝚜𝚙𝚒𝚛𝚎𝚍 𝙻𝚒𝚟𝚒𝚗𝚐 𝙱𝚎𝚐𝚒𝚗𝚜 𝙷𝚎𝚛𝚎"</p> */}
            <div className="home-page-model-left">
       
      <section class="wrapperr">
  <div class="topr">Moonkiri Design</div>
  <div class="bottomr" aria-hidden="true">Moonkiri Design</div>
</section>
<section class="wrapperr" style={{marginTop:"-30px"}}>
  <div class="topr">Studio</div>
  <div class="bottomr" aria-hidden="true">Studio</div>
</section>
   
              {/* <h1 style={{letterSpacing:"2px"}} className='interior-text'>𝖨𝗇𝗍𝖾𝗋𝗂𝗈𝗋 𝖣𝖾𝗌𝗂𝗀𝗇 𝖲𝗍𝗎𝖽𝗂𝗈</h1> */}
              <section class="wrapperr">
  <div class="topr">X</div>
  <div class="bottomr" aria-hidden="true">X</div>
</section>
     
          
                
                <section class="wrapperr">
  <div class="topr">Urban Furniture</div>
  <div class="bottomr" aria-hidden="true">Urban Furniture</div>
</section>
            </div>
            <div className="home-page-model-right">
              
            </div>
          </div>
            
        </div>
        <div className="projects-container" style={{marginTop:"-30px"}}>
         
        <div class="text-boxx" style={{textAlign:"left"}}>
		<h1 style={{ fontSize:"2.5rem" , letterSpacing:"5px"}}>View Projects</h1>
		<h1 style={{fontSize:"2.5rem" , letterSpacing:"5px"}}>View Projects</h1>
	</div>
          
            <div className="project-model">
                <div className="each-project-model-with-title">

                  <h3>Design Projects</h3>
                <div className="each-project-model" onClick={() => handleProjectClick("design-project")}>
                  <h3 className="hover-title">𝚅𝚒𝚎𝚠 𝚖𝚘𝚛𝚎</h3>
             
                  <img src={modern1} alt="" />
                </div>
                </div>
                <div className="each-project-model-with-title">
                  
                  <h3>Construction Projects</h3>
                <div className="each-project-model" onClick={() => handleProjectClick("construction-project")}>
                  <h3 className="hover-title">𝚅𝚒𝚎𝚠 𝚖𝚘𝚛𝚎</h3>
                  <img src={modern2} alt="" />
                </div>
                </div>
                <div className="each-project-model-with-title">
                  
                <h3>CNC Projects</h3>
                <div className="each-project-model" onClick={() => handleProjectClick("cnc-project")}>
                <h3 className="hover-title">𝚅𝚒𝚎𝚠 𝚖𝚘𝚛𝚎</h3>
                <img src={modern3} alt="" />
                </div>
                </div>
              </div>
        
        </div>
        <div className="products-model-part">
        <div class="text-boxx" style={{textAlign:"left"}}>
		<h1 style={{ fontSize:"2.5rem" , letterSpacing:"5px"}}>Product Categories</h1>
		<h1 style={{fontSize:"2.5rem" , letterSpacing:"5px"}}>Product Categories</h1>
	</div>
          <div className="all-products-model">
            <div className="each-product-model" onClick={() =>{handleProjectDisplay("tables")}}>
              <div className="product-text-model">
                <h3>Ｔａｂｌｅ</h3>
                <button onClick={() =>{handleProjectDisplay("tables")}}>Shop Now</button>
              </div>
              <img src={table} alt="" />
            </div>
            <div className="each-product-model" onClick={() =>{handleProjectDisplay("cabinets")}}>
              <div className="product-text-model">
                <h3>Ｃａｂｉｎｅｔ</h3>
                <button onClick={() =>{handleProjectDisplay("cabinets")}}>Shop Now</button>
              </div>
              <img src={cabinet} alt="" />
            </div>
            <div className="each-product-model" onClick={() =>{handleProjectDisplay("shelves")}}>
              <div className="product-text-model">
                <h3>Ｓｈｅｌｆ</h3>
                <button onClick={() =>{handleProjectDisplay("shelves")}}>Shop Now</button>
              </div>
              <img src={shelf} alt="" />
            </div>
          </div>
        </div>
        <div className="contact-bottom">
          <div className="contact-blocks first">
          <div className="contact-studio-location">
          <h3>𝖬𝗈𝗈𝗇𝗄𝗂𝗋𝗂</h3>
            <p className='contact-desc'>X</p>
            <h3>𝖴𝗋𝖻𝖺𝗇 𝖥𝗎𝗋𝗇𝗂𝗍𝗎𝗋𝖾</h3>
              </div>
            
            <p>𝙰 𝚌𝚘𝚖𝚙𝚊𝚗𝚢 𝚝𝚑𝚊𝚝 𝚌𝚛𝚎𝚊𝚝𝚎𝚜 𝚢𝚘𝚞𝚛 𝚍𝚛𝚎𝚊𝚖 𝚜𝚙𝚊𝚌𝚎 𝚠𝚒𝚝𝚑 𝚙𝚎𝚛𝚜𝚘𝚗𝚊𝚕𝚒𝚣𝚎𝚍 𝚒𝚗𝚝𝚎𝚛𝚒𝚘𝚛 𝚍𝚎𝚜𝚒𝚐𝚗, 𝚌𝚘𝚗𝚜𝚝𝚛𝚞𝚌𝚝𝚒𝚘𝚗, 𝚊𝚗𝚍 𝚌𝚞𝚜𝚝𝚘𝚖 𝚏𝚞𝚛𝚗𝚒𝚝𝚞𝚛𝚎, 𝚊𝚕𝚕 𝚌𝚛𝚊𝚏𝚝𝚎𝚍 𝚠𝚒𝚝𝚑 𝚙𝚛𝚎𝚌𝚒𝚜𝚒𝚘𝚗 𝚊𝚗𝚍 𝚙𝚛𝚎𝚖𝚒𝚞𝚖 𝚖𝚊𝚝𝚎𝚛𝚒𝚊𝚕𝚜 𝚞𝚜𝚒𝚗𝚐 𝚊𝚍𝚟𝚊𝚗𝚌𝚎𝚍 𝙲𝙽𝙲 𝚝𝚎𝚌𝚑𝚗𝚘𝚕𝚘𝚐𝚢, 𝚎𝚗𝚜𝚞𝚛𝚒𝚗𝚐 𝚎𝚡𝚌𝚎𝚙𝚝𝚒𝚘𝚗𝚊𝚕 𝚚𝚞𝚊𝚕𝚒𝚝𝚢 𝚊𝚝 𝚊𝚏𝚏𝚘𝚛𝚍𝚊𝚋𝚕𝚎 𝚙𝚛𝚒𝚌𝚎𝚜."</p>
          </div>
          <div className="contact-blocks">
              <h3>𝖵𝗂𝗌𝗂𝗍 𝖴𝗌</h3>
              <div className="contact-location">
                <h3>𝖴𝗋𝖻𝖺𝗇 𝖥𝗎𝗋𝗇𝗂𝗍𝗎𝗋𝖾</h3>
                <p>𝚂𝚊𝚗𝚘𝚐𝚊𝚞𝚗, 𝙻𝚊𝚕𝚒𝚝𝚙𝚞𝚛, 𝙽𝚎𝚙𝚊𝚕</p>
              </div>
              <div className="contact-studio-location">
                <h3>𝖬𝗈𝗈𝗇𝗄𝗂𝗋𝗂 𝖣𝖾𝗌𝗂𝗀𝗇 𝖲𝗍𝗎𝖽𝗂𝗈</h3>
                <p>𝚂𝚊𝚗𝚘𝚐𝚊𝚞𝚗, 𝙻𝚊𝚕𝚒𝚝𝚙𝚞𝚛, 𝙽𝚎𝚙𝚊𝚕</p>
              </div>
          </div>
          <div className="contact-blocks">
              <h3>𝖢𝗈𝗇𝗍𝖺𝖼𝗍</h3>
              
              <p>𝚖𝚘𝚘𝚗𝚔𝚒𝚛𝚒𝚍𝚎𝚜𝚒𝚐𝚗@𝚐𝚖𝚊𝚒𝚕.𝚌𝚘𝚖</p>
              <p> 𝚞𝚛𝚋𝚊𝚗𝚏𝚞𝚛𝚗𝚒𝚝𝚞𝚛𝚎2022@𝚐𝚖𝚊𝚒𝚕.𝚌𝚘𝚖</p>
          </div>
          <div className="contact-blocks">
            <h3>𝖬𝗈𝗈𝗇𝗄𝗂𝗋𝗂</h3>
              <div className="contact-connect-icons">
              <a href="https://www.instagram.com/moonkiri/" target='_blank' ><img src={instaIcon} className='GIT-icons' /></a>
              <a href="mailto:moonkiridesign@gmail.com"><img src={gmail} className='GIT-icons'/></a>
              <a href="whatsapp://send?phone=+9779860124709" ><img src={whatsapp} className='GIT-icons'/></a>
              <a href="https://www.facebook.com/moonkiristudio" target='_blank'><img src={facebook} className='GIT-icons'/></a>
              </div>
              <h3>𝖴𝗋𝖻𝖺𝗇 𝖥𝗎𝗋𝗇𝗂𝗍𝗎𝗋𝖾</h3>
              <div className="contact-connect-icons">
              <a href="https://www.instagram.com/urban.furniture22?igsh=MWxlOHhjNGZlem4w" target='_blank' ><img src={instaIcon} className='GIT-icons' /></a>
              <a href="mailto:moonkiridesign@gmail.com"><img src={gmail} className='GIT-icons'/></a>
              <a href="whatsapp://send?phone=+9779860124709" ><img src={whatsapp} className='GIT-icons'/></a>
              <a href="https://www.facebook.com/share/r4pmAq96pEzkdW3g/?mibextid=qi2Omg" target='_blank'><img src={facebook} className='GIT-icons'/></a>
              </div>
          </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default Home