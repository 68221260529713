import React, { useRef, useState } from 'react'
import back1 from '../../imgs/back1.jpg'
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import MetaData from "../../MetaData";
import { AiOutlineEnter } from "react-icons/ai";
import { FaArrowDownLong } from "react-icons/fa6";
import modern1 from '../../assests/renders/4.budanilkantha kitchen/1.jpg'
import modern2 from '../../assests/renders/6.dhapa height/1.jpg'
import modern3 from '../../assests/renders/7.kausaltar/4.jpg'
import modern4 from '../../assests/renders/10.pepsicola/1.jpg'
import modern5 from '../../assests/renders/12.shoymbu/2.jpg'
import modern6 from '../../assests/renders/13.Thasi/1.jpg'
import modern7 from '../../assests/renders/15.Thimi daisy/1.jpg'
import modern8 from '../../assests/renders/16.tokha/1.jpg'
import modern9 from '../../assests/renders/17.united colony/FINAL1.jpg'
import modern10 from '../../assests/renders/18.old thimi kitchen/2.effectsResult.jpg'
import modernclassical from '../../assests/renders/8.khumaltar/2.jpg'
import classical from '../../assests/renders/14.thimi classical/1.jpg'
import neoclassical from '../../assests/renders/2.budanilkantha 1/1.jpg'
import neoclassical1 from '../../assests/renders/3.budanilkantha 2/2.png'
import boho from '../../assests/renders/11.salon de/1.jpg'




import "./project.css"

const categories = [
  'Modern',
  'Modern Classical',
  'Classical',
  'Neo classical',
  "Boho"
  
];

const Modern =[
  {
    title:"Budanilkantha-Residence" , 
    place:"Harisiddhi",
    image:modern1
  },
  {
    title:"Dhapa height-Residence" , 
    place:"Harisiddhi",
    image:modern2
  },
  {
    title:"Kausaltar - Residence" , 
    place:"Harisiddhi",
    image:modern3
  },
  {
    title:"Pepsicola- Residence" , 
    place:"Harisiddhi",
    image:modern4
  },
  {
    title:"Shoymbu- Residence" , 
    place:"Harisiddhi",
    image:modern5
  },
  {
    title:"Thasi-Residence" , 
    place:"Harisiddhi",
    image:modern6
  },
  {
    title:"Thimi (daisy)- residence" , 
    place:"Harisiddhi",
    image:modern7
  },
  {
    title:"Tokha - Residence" , 
    place:"Harisiddhi",
    image:modern8
  },
  {
    title:"United colony - Residence" , 
    place:"Harisiddhi",
    image:modern9
  },
  {
    title:"Thimi (sanu badra)- Residence" , 
    place:"Harisiddhi",
    image:modern10
  },
]



const ModernClassical =[
  {
    title:"Khumaltar - Residence" , 
    place:"Harisiddhi",
    image:modernclassical
  },
  
]

const Classical =[
  {
    title:"Thimi- residence" , 
    place:"Harisiddhi",
    image:classical
  },
 
]

const Neoclassical =[

  {
    title:"Budanilkantha-Residence1" , 
    place:"Harisiddhi",
    image:neoclassical
  },
  {
    title:"Budanilkantha-Residence2" , 
    place:"Harisiddhi",
    image:neoclassical1
  },

 
]

const Boho =[
  {
    title:"Salon de Cafe-Commercial" , 
    place:"Harisiddhi",
    image:boho
  },
]


function Project() {
  const [activeCategory, setActiveCategory] = useState(0);
  const [isCategoryClicked, setIsCategoryClicked] = useState(activeCategory === 0 ? true : false);
  const bottomRef = useRef(null);

  const { ref: frontTopRef, inView: frontTopInView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });
  console.log(categories)
  const handleCategoryClick = (e, index) => {
    e.preventDefault();
    setActiveCategory(index);
    setIsCategoryClicked(true);
  };

  const handleProjectDisplay = (title) => {

    const bookingRoute = `/project/${title}`;
   
    window.location.href = bookingRoute;
    
  };

  return (
    <>
    <MetaData title="Design Project" />
    <div className='project-display-model'>
        <div className="pd-top" style={{backgroundImage:`url(${back1})`}}>
        <div class="text-boxx">
		<h1>Design Projects</h1>
		<h1>Design Projects</h1>
	</div>
            <FaArrowDownLong className='project-display-model-arrow'/>
        </div>
        <div className='project-display-model-container'>
        <div className="project-categories model">
            {categories?.map((category, index) => (
                <div >
                  <AiOutlineEnter className={index === activeCategory ? 'category-arrow active' : 'category-arrow'}/>
                  <motion.a
                      href="#"
                      className={index === activeCategory ? 'active' : ''}
                      onClick={(e) => {handleCategoryClick(e, index); console.log(index)}}
                      
                        animate={index === activeCategory  ? 'visible' : 'hidden'}
                    variants={{
                      visible: {
                        opacity: 1,
                        y:0 ,
                        
                        transition: {
                          ease: 'backIn',
                          duration: .5,
                        },
                      },
                      hidden: {
                        opacity: 0.6,
                        y:10
                        
                      },
                    }} 
                    >
                      {category }
                     
                  </motion.a>
                </div>
                
              ))}
            </div>
            {activeCategory !== null && (
          <div className="project-main">
            {categories[activeCategory] === "Modern" && (
              // Render projects from the Modern array
              Modern.map((project, index) => (
                <div className='project-hover-model'>
             <h3 >{project.title}</h3> 
          <div class="card" onClick={() =>handleProjectDisplay(project.title)}>
            <div className="img">
                <img
                   src={project.image} alt="" 
                 />
            </div>
            <div class="textBox">
              <p class="text head">{project.title}</p>
              <span>View More</span>
             
            </div>
          </div>
        </div>
              ))
            )}

            {categories[activeCategory] === "Modern Classical" && (
              // Render projects from the Neo classical array
              ModernClassical.map((project, index) => (
                <div className='project-hover-model'>
             <h3 >{project.title}</h3> 
          <div class="card" onClick={() =>handleProjectDisplay(project.title)}>
            <div className="img">
                <img
                   src={project.image} alt="" 
                 />
            </div>
            <div class="textBox">
              <p class="text head">{project.title}</p>
              <span>View More</span>
             
            </div>
          </div>
        </div>
              ))
            )}

            {categories[activeCategory] === "Classical" && (
              // Render projects from the Public Housing array
              Classical.map((project, index) => (
                <div className='project-hover-model'>
             <h3 >{project.title}</h3> 
          <div class="card" onClick={() =>handleProjectDisplay(project.title)}>
            <div className="img">
                <img
                   src={project.image} alt="" 
                 />
            </div>
            <div class="textBox">
              <p class="text head">{project.title}</p>
              <span>View More</span>
             
            </div>
          </div>
        </div>
              ))
            )}
            {categories[activeCategory] === "Neo classical" && (
              // Render projects from the Public Housing array
              Neoclassical.map((project, index) => (
                <div className='project-hover-model'>
             <h3 >{project.title}</h3> 
          <div class="card" onClick={() =>handleProjectDisplay(project.title)}>
            <div className="img">
                <img
                   src={project.image} alt="" 
                 />
            </div>
            <div class="textBox">
              <p class="text head">{project.title}</p>
              <span>View More</span>
             
            </div>
          </div>
        </div>
              ))
            )}
            {categories[activeCategory] === "Boho" && (
              // Render projects from the Public Housing array
              Boho.map((project, index) => (
                <div className='project-hover-model'>
             <h3 >{project.title}</h3> 
          <div class="card" onClick={() =>handleProjectDisplay(project.title)}>
            <div className="img">
                <img
                   src={project.image} alt="" 
                 />
            </div>
            <div class="textBox">
              <p class="text head">{project.title}</p>
              <span>View More</span>
             
            </div>
          </div>
        </div>
              ))
            )}
            


            {/* Add similar conditions for other categories */}
          </div>
        )}
        </div>
    </div>
    </>
  )
}

export default Project