
import table from '../../imgs/back1.jpg'
import cabinet from '../../imgs/back1.jpg'
import shelf from '../../imgs/back1.jpg'
import budanResi1 from '../../assests/renders/4.budanilkantha kitchen/1.jpg'
import budanResi2 from '../../assests/renders/4.budanilkantha kitchen/2.jpg'
import budanResi3 from '../../assests/renders/4.budanilkantha kitchen/3.jpg'
import budanResi4 from '../../assests/renders/4.budanilkantha kitchen/4.jpg'
import dhapaheight1 from '../../assests/renders/6.dhapa height/1.jpg'
import dhapaheight2 from '../../assests/renders/6.dhapa height/2.jpg'
import dhapaheight3 from '../../assests/renders/6.dhapa height/3.jpg'
import dhapaheight4 from '../../assests/renders/6.dhapa height/4.jpg'
import dhapaheight5 from '../../assests/renders/6.dhapa height/5.jpg'
import kausal1 from '../../assests/renders/7.kausaltar/1.jpg'
import kausal2 from '../../assests/renders/7.kausaltar/2.jpeg'
import kausal3 from '../../assests/renders/7.kausaltar/3.jpg'
import kausal4 from '../../assests/renders/7.kausaltar/4.jpg'
import kausal5 from '../../assests/renders/7.kausaltar/5.jpg'
import kausal6 from '../../assests/renders/7.kausaltar/6.jpg'
import kausal7 from '../../assests/renders/7.kausaltar/7.png'
import pepsicola1 from '../../assests/renders/10.pepsicola/1.jpg'
import pepsicola2 from '../../assests/renders/10.pepsicola/2.jpg'
import pepsicola3 from '../../assests/renders/10.pepsicola/3.jpg'
import pepsicola4 from '../../assests/renders/10.pepsicola/4.jpg'
import pepsicola5 from '../../assests/renders/10.pepsicola/5.jpg'
import pepsicola6 from '../../assests/renders/10.pepsicola/6.jpg'
import pepsicola7 from '../../assests/renders/10.pepsicola/7.jpg'
import pepsicola8 from '../../assests/renders/10.pepsicola/8.jpg'
import pepsicola9 from '../../assests/renders/10.pepsicola/9.jpg'
import shoymbu1 from '../../assests/renders/12.shoymbu/1.png'
import shoymbu2 from '../../assests/renders/12.shoymbu/2.jpg'
import shoymbu3 from '../../assests/renders/12.shoymbu/3.jpg'
import thasi1 from '../../assests/renders/13.Thasi/1.jpg'
import thasi2 from '../../assests/renders/13.Thasi/2.jpg'
import thimidasy1 from '../../assests/renders/15.Thimi daisy/1.jpg'
import thimidasy2 from '../../assests/renders/15.Thimi daisy/2.jpg'
import thimidasy3 from '../../assests/renders/15.Thimi daisy/3.jpg'
import thimidasy4 from '../../assests/renders/15.Thimi daisy/4.jpg'
import thimidasy5 from '../../assests/renders/15.Thimi daisy/5.jpg'
import thimidasy6 from '../../assests/renders/15.Thimi daisy/6.jpg'
import tokha1 from '../../assests/renders/16.tokha/1.jpg'
import tokha2 from '../../assests/renders/16.tokha/2.jpg'
import tokha3 from '../../assests/renders/16.tokha/3.jpg'
import tokha4 from '../../assests/renders/16.tokha/4.jpg'
import tokha5 from '../../assests/renders/16.tokha/5.jpg'
import unitedcolony1 from '../../assests/renders/17.united colony/E.jpg'
import unitedcolony2 from '../../assests/renders/17.united colony/F.jpg'
import unitedcolony3 from '../../assests/renders/17.united colony/FINAL1.jpg'
import oldthimi1 from '../../assests/renders/18.old thimi kitchen/2.effectsResult.jpg'
import oldthimi2 from '../../assests/renders/18.old thimi kitchen/3final.jpg'
import oldthimi3 from '../../assests/renders/18.old thimi kitchen/4.jpg'
import oldthimi4 from '../../assests/renders/18.old thimi kitchen/final.jpg'
import khumaltar1 from '../../assests/renders/8.khumaltar/1.png'
import khumaltar2 from '../../assests/renders/8.khumaltar/2.jpg'
import khumaltar3 from '../../assests/renders/8.khumaltar/3.jpg'
import khumaltar4 from '../../assests/renders/8.khumaltar/4.jpg'
import khumaltar5 from '../../assests/renders/8.khumaltar/5.jpg'
import khumaltar6 from '../../assests/renders/8.khumaltar/6.jpg'
import khumaltar7 from '../../assests/renders/8.khumaltar/7.jpg'
import khumaltar8 from '../../assests/renders/8.khumaltar/8.jpg'
import khumaltar9 from '../../assests/renders/8.khumaltar/9.jpg'
import thimiclassical1 from '../../assests/renders/14.thimi classical/1.jpg'
import thimiclassical2 from '../../assests/renders/14.thimi classical/2.jpg'
import thimiclassical3 from '../../assests/renders/14.thimi classical/3.jpg'
import thimiclassical4 from '../../assests/renders/14.thimi classical/4.jpg'
import thimiclassical5 from '../../assests/renders/14.thimi classical/5.jpg'
import thimiclassical6 from '../../assests/renders/14.thimi classical/6.jpg'
import thimiclassical7 from '../../assests/renders/14.thimi classical/7.jpg'
import thimiclassical8 from '../../assests/renders/14.thimi classical/8.jpg'
import thimiclassical9 from '../../assests/renders/14.thimi classical/9.jpg'
import thimiclassical10 from '../../assests/renders/14.thimi classical/10.jpg'
import budanineo1clasical1 from '../../assests/renders/2.budanilkantha 1/1.jpg'
import budanineo1clasical2 from '../../assests/renders/2.budanilkantha 1/2.jpg'
import budanineo1clasical3 from '../../assests/renders/2.budanilkantha 1/3.jpg'
import budanineo1clasical4 from '../../assests/renders/2.budanilkantha 1/4.jpg'
import budanineo1clasical5 from '../../assests/renders/2.budanilkantha 1/5.jpg'
import budanineo1clasical6 from '../../assests/renders/2.budanilkantha 1/6.jpg'
import budanineo1clasical7 from '../../assests/renders/2.budanilkantha 1/7.jpg'
import budanineo1clasical8 from '../../assests/renders/2.budanilkantha 1/8.jpg'
import budanineo2clasical1 from '../../assests/renders/3.budanilkantha 2/1.png'
import budanineo2clasical2 from '../../assests/renders/3.budanilkantha 2/2.png'
import budanineo2clasical3 from '../../assests/renders/3.budanilkantha 2/3.jpg'
import budanineo2clasical4 from '../../assests/renders/3.budanilkantha 2/4.jpg'
import budanineo2clasical5 from '../../assests/renders/3.budanilkantha 2/5.jpg'
import budanineo2clasical6 from '../../assests/renders/3.budanilkantha 2/6.jpg'
import budanineo2clasical7 from '../../assests/renders/3.budanilkantha 2/7.jpg'
import budanineo2clasical8 from '../../assests/renders/3.budanilkantha 2/8.png'
import budanineo2clasical9 from '../../assests/renders/3.budanilkantha 2/9.jpg'
import budanineo2clasical10 from '../../assests/renders/3.budanilkantha 2/10.jpg'
import budanineo2clasical11 from '../../assests/renders/3.budanilkantha 2/11.jpg'
import budanineo2clasical12 from '../../assests/renders/3.budanilkantha 2/12.jpg'
import budanineo2clasical13 from '../../assests/renders/3.budanilkantha 2/13.jpg'
import budanineo2clasical14 from '../../assests/renders/3.budanilkantha 2/14.jpg'
import salonde1 from '../../assests/renders/11.salon de/1.jpg'
import salonde2 from '../../assests/renders/11.salon de/2.jpg'
import salonde3 from '../../assests/renders/11.salon de/3.jpg'
import salonde4 from '../../assests/renders/11.salon de/4.jpg'
import salonde5 from '../../assests/renders/11.salon de/5.jpg'
import salonde6 from '../../assests/renders/11.salon de/6.jpg'
import salonde7 from '../../assests/renders/11.salon de/7.jpg'
import kitchenconstructionwork1 from '../../assests/renders/Construction Projects/1/1.jpg'
import kitchenconstructionwork2 from '../../assests/renders/Construction Projects/1/2.jpg'
import kitchenconstructionwork3 from '../../assests/renders/Construction Projects/1/3.jpg'
import kitchenconstructionwork4 from '../../assests/renders/Construction Projects/1/4.jpg'
import khumaltarresidence1 from '../../assests/renders/Construction Projects/2/1.jpg'
import khumaltarresidence2 from '../../assests/renders/Construction Projects/2/2.jpg'
import khumaltarresidence3 from '../../assests/renders/Construction Projects/2/3.jpg'
import khumaltarresidence4 from '../../assests/renders/Construction Projects/2/4.jpg'
import khumaltarresidence5 from '../../assests/renders/Construction Projects/2/5.jpg'
import khumaltarresidence6 from '../../assests/renders/Construction Projects/2/6.jpg'
import khumaltarresidence7 from '../../assests/renders/Construction Projects/2/7.jpg'
import khumaltarresidence8 from '../../assests/renders/Construction Projects/2/8.jpg'
import livingroomconstruction1 from '../../assests/renders/Construction Projects/3/1.jpg'
import livingroomconstruction2 from '../../assests/renders/Construction Projects/3/2.jpg'
import livingroomconstruction3 from '../../assests/renders/Construction Projects/3/3.jpg'
import livingroomconstruction4 from '../../assests/renders/Construction Projects/3/4.jpg'
import livingroomconstruction5 from '../../assests/renders/Construction Projects/3/5.jpg'
import livingroomconstruction6 from '../../assests/renders/Construction Projects/3/6.jpg'
import livingroomconstruction7 from '../../assests/renders/Construction Projects/3/7.jpg'
import livingroomconstruction8 from '../../assests/renders/Construction Projects/3/8.jpg'
import daisyresidance1 from '../../assests/renders/Construction Projects/4/1.jpg'
import daisyresidance2 from '../../assests/renders/Construction Projects/4/2.jpg'
import daisyresidance3 from '../../assests/renders/Construction Projects/4/3.jpg'
import daisyresidance4 from '../../assests/renders/Construction Projects/4/4.jpg'
import pepsicolaresidance1 from '../../assests/renders/Construction Projects/5/1.jpg'
import pepsicolaresidance2 from '../../assests/renders/Construction Projects/5/2.jpg'
import pepsicolaresidance3 from '../../assests/renders/Construction Projects/5/3.jpg'
import pepsicolaresidance4 from '../../assests/renders/Construction Projects/5/4.jpg'
import pepsicolaresidance5 from '../../assests/renders/Construction Projects/5/5.jpg'
import pepsicolaresidance6 from '../../assests/renders/Construction Projects/5/6.jpg'
import pepsicolaresidance7 from '../../assests/renders/Construction Projects/5/7.jpg'
import thansiresidance1 from '../../assests/renders/Construction Projects/6/1.jpg'
import thansiresidance2 from '../../assests/renders/Construction Projects/6/2.jpg'
import thansiresidance3 from '../../assests/renders/Construction Projects/6/3.jpg'
import thansiresidance4 from '../../assests/renders/Construction Projects/6/4.jpg'
import thansiresidance5 from '../../assests/renders/Construction Projects/6/5.jpg'
import thansiresidance6 from '../../assests/renders/Construction Projects/6/6.jpg'
import thansiresidance7 from '../../assests/renders/Construction Projects/6/7.jpg'
import thansiresidance8 from '../../assests/renders/Construction Projects/6/8.jpg'
import thansiresidance9 from '../../assests/renders/Construction Projects/6/9.jpg'
import officeconstruction1 from '../../assests/renders/Construction Projects/7/1.jpg'
import officeconstruction2 from '../../assests/renders/Construction Projects/7/2.jpg'
import officeconstruction3 from '../../assests/renders/Construction Projects/7/3.jpg'
import officeconstruction4 from '../../assests/renders/Construction Projects/7/4.jpg'
import officeconstruction5 from '../../assests/renders/Construction Projects/7/5.jpg'
import officeconstruction6 from '../../assests/renders/Construction Projects/7/6.jpg'
import cnc1 from '../../imgs/CNC JALI PARTITION/1.jpg'
import cnc2 from '../../imgs/CNC JALI PARTITION/2.jpg'
import cnc3 from '../../imgs/CNC JALI PARTITION/3.jpg'
import cnc4 from '../../imgs/CNC JALI PARTITION/4.jpg'
import cnc5 from '../../imgs/CNC JALI PARTITION/5.jpg'
import cnc6 from '../../imgs/CNC JALI PARTITION/6.jpg'
import cnc7 from '../../imgs/CNC JALI PARTITION/7.jpg'
import cnc8 from '../../imgs/CNC JALI PARTITION/8.jpg'
import cnc9 from '../../imgs/CNC JALI PARTITION/9.jpg'
import cnc10 from '../../imgs/CNC JALI PARTITION/10.jpg'

export const menu = [{
    id: 1,
    title: "Budanilkantha-Residence",
    images: [
        budanResi1,
        budanResi2,
        budanResi3,
        budanResi4,
    ],
    client:"AnupRaj Bista",
    location : "Budanilkantha" , 
    

},
{
    id: 2,
    title: "Dhapa height-Residence",
    images: [
        dhapaheight1,
        dhapaheight2,
        dhapaheight3,
        dhapaheight4,
        dhapaheight5,
    ],
    client:"Prakash Bhandari",
    location : "Dhapa height" , 
    

},
{
    id: 3,
    title: "Kausaltar - Residence",
    images: [
        kausal1,
        kausal2,
        kausal3,
        kausal4,
        kausal5,
        kausal6,
        kausal7,
    ],
    client:"Suresh Pyatha",
    location : "Kausaltar" , 
    

},
{
    id: 4,
    title: "Pepsicola- Residence",
    images: [
       pepsicola1,
       pepsicola2,
       pepsicola3,
       pepsicola4,
       pepsicola5,
       pepsicola6,
       pepsicola7,
       pepsicola8,
       pepsicola9,
    ],
    client:"Rajan Khadka",
    location : "Pepsicola" , 
    

},
{
    id: 5,
    title: "Shoymbu- Residence",
    images: [
       shoymbu1,
       shoymbu2,
       shoymbu3,
    ],
    client:"Samita Thakali",
    location : "Shoymbu" , 
    

},
{
    id: 6,
    title: "Thasi-Residence",
    images: [
        thasi1,
        thasi2
       
    ],
    client:"Ramesh Amatya",
    location : "Thasi" , 
    

},
{
    id: 7,
    title: "Thimi (daisy)- residence",
    images: [
        thimidasy1,
        thimidasy2,
        thimidasy3,
        thimidasy4,
        thimidasy5,
        thimidasy6,
    ],
    client:"Classified",
    location : "Purano Thimi" , 
    

},
{
    id: 8,
    title: "Tokha - Residence",
    images: [
        tokha1,
        tokha2,
        tokha3,
        tokha4,
        tokha5,

    ],
    client:"Deepana Lama",
    location : "Tokha" , 
    

},
{
    id: 9,
    title: "United colony - Residence",
    images: [
        unitedcolony1,
        unitedcolony2,
        unitedcolony3,
        
       
    ],
    client:"Classified",
    location : "Lubhu" , 
    

},
{
    id: 10,
    title: "Thimi (sanu badra)- Residence",
    images: [
        oldthimi1,
        oldthimi2,
        oldthimi3,
        oldthimi4,
    ],
    client:"Sanu Badra bajracharya",
    location : "Purano Thimi" , 
    

},
{
    id: 11,
    title: "Khumaltar - Residence",
    images: [
        khumaltar1 ,
        khumaltar2 ,
        khumaltar3 ,
        khumaltar4 ,
        khumaltar5 ,
        khumaltar6 ,
        khumaltar7 ,
        khumaltar8 ,
        khumaltar9 ,
    ],
    client:"Dipesh Pokherel",
    location : "Khumaltar" , 
    

},
{
    id: 12,
    title: "Thimi- residence",
    images: [
        thimiclassical1,
        thimiclassical2,
        thimiclassical3,
        thimiclassical4,
        thimiclassical5,
        thimiclassical6,
        thimiclassical7,
        thimiclassical8,
        thimiclassical9,
        thimiclassical10,

    ],
    client:"",
    location : "Purano Thimi" , 
    

},
{
    id: 13,
    title: "Thimi (sanu badra)- Residence",
    images: [
        oldthimi1,
        oldthimi2,
        oldthimi3,
        oldthimi4,
    ],
    client:"Sanu Badra bajracharya",
    location : "Purano Thimi" , 
    

},
{
    id: 14,
    title: "Budanilkantha-Residence1",  
    images: [
        budanineo1clasical1,
        budanineo1clasical2,
        budanineo1clasical3,
        budanineo1clasical4,
        budanineo1clasical5,
        budanineo1clasical6,
        budanineo1clasical7,
        budanineo1clasical8,
    ],
    client:"Kedar Rimal",
    location : "Budanilkantha" , 
    

},
{
    id: 15,
    title: "Budanilkantha-Residence2",
    images: [
        budanineo2clasical1,
        budanineo2clasical2,
        budanineo2clasical3,
        budanineo2clasical4,
        budanineo2clasical5,
        budanineo2clasical6,
        budanineo2clasical7,
        budanineo2clasical8,
        budanineo2clasical9,
        budanineo2clasical10,
        budanineo2clasical11,
        budanineo2clasical12,
        budanineo2clasical13,
        budanineo2clasical14,
    ],
    client:"Dambar Biswokarma",
    location : "Budanilkantha" , 
    

},
{
    id: 16,
    title: "Salon de Cafe-Commercial",
    images: [
        salonde1,
        salonde2,
        salonde3,
        salonde4,
        salonde5,
        salonde6,
        salonde7,
    ],
    client:"Jenifer ",
    location : "Lazimpat" , 
    

},
{
    id: 17,
    title: "Kitchen Construction works",
    images: [
        kitchenconstructionwork1,
        kitchenconstructionwork2,
        kitchenconstructionwork3,
        kitchenconstructionwork4,
    ],
    client:"Roji Maharjan",
    location : "Imadole, Lalitpur" , 
    

},
{
    id: 18,
    title: "Khumaltar Residence",
    images: [
        khumaltarresidence1,
        khumaltarresidence2,
        khumaltarresidence3,

        khumaltarresidence4,
        khumaltarresidence5,
        khumaltarresidence6,
        khumaltarresidence7,
        khumaltarresidence8,
        
    ],
    client:"Deepesh Pokhrel",
    location : "Khumaltar, Lalitpur" , 
    

},
{
    id: 19,
    title: "Living Room Construction",
    images: [
        livingroomconstruction1,
        livingroomconstruction2,
        livingroomconstruction3,
        livingroomconstruction4,
        livingroomconstruction5,
        livingroomconstruction6,
        livingroomconstruction7,
        livingroomconstruction8,
    ],
    client:"Jen Bati ",
    location : "Ittachen, Bhaktapur" , 
    

},
{
    id: 20,
    title: "Daisy Residence",
    images: [
        daisyresidance1,
        daisyresidance2,
        daisyresidance3,
        daisyresidance4,
    ],
    client:"Saraswati Prajapati ",
    location : "Saraswati Prajapati" , 
    

},
{
    id: 21,
    title: "Pepsicola Residence",
    images: [
        pepsicolaresidance1,
        pepsicolaresidance2,
        pepsicolaresidance3,
        pepsicolaresidance4,
        pepsicolaresidance5,
        pepsicolaresidance6,
        pepsicolaresidance7,
    ],
    client:"Rajan Khadka ",
    location : "Pepsicola, Bhaktapur" , 
    

},
{
    id: 22,
    title: "Thasi Residence",
    images: [
        thansiresidance1,
        thansiresidance2,
        thansiresidance3,
        thansiresidance4,
        thansiresidance5,
        thansiresidance6,
        thansiresidance7,
        thansiresidance8,
        thansiresidance9,
    ],
    client:"Ramesh Amatya ",
    location : "Sanagaun, Lalitpur" , 
    

},
{
    id: 23,
    title: "Office Construction",
    images: [
        officeconstruction1,
        officeconstruction2,
        officeconstruction3,
        officeconstruction4,
        officeconstruction5,
        officeconstruction6,
    ],
    client:"Zen Consultancy Pvt Ltd",
    location : "Baneshwor, Kathmandu" , 
    
   
},

{
    id: 24,
    title: "CNC JALI PARTITION",
    images: [
        cnc1,
        cnc2,
        cnc3,
        cnc4,
        cnc5,
        cnc6,
        cnc7,
        cnc8,
        cnc9,
        cnc10,

    ],
    client:"Zen Consultancy Pvt Ltd",
    location : "Baneshwor, Kathmandu" , 
    
    
},


];

export const products = [{
    id: 1,
    title: "shelves",
    images: shelf,

},
{
    id: 2,
    title: "tables",
    images: table,

},
{
    id: 3,
    title: "cabinets",
    images: cabinet,

}]