import { useEffect, useState } from 'react';
import logo from '../../imgs/logo.svg'
import logo1 from '../../imgs/logo1.svg'
import urban from '../../imgs/Urban.svg'
import furniture from '../../imgs/furniture.svg'
import { FaArrowDownLong } from "react-icons/fa6";
import { motion, useAnimation } from 'framer-motion';
import { CiMenuBurger } from "react-icons/ci";
import { MdOutlineClose } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { Link as ScrollLink, animateScroll } from 'react-scroll';
import { useHistory } from "react-router-dom";
import './header.css'

function Headers() {
  const history = useHistory();
  const pathname = history.location.pathname;
  const [isNavActive, setIsNavActive] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showNav, setShowNav] = useState(false)
  const [resProduct, setResProduct] = useState(false)
  const [logoActive, setLogoActive] = useState(false);

  const controls = useAnimation();

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

   const handleLogoClick = () => {

    history.push("/")
    setLogoActive(false)
  };

  const handleContact = () =>{
    const bookingRoute = `/contact`;
   
    window.location.href = bookingRoute;
    
    setShowNav(!showNav);
    setLogoActive(false)
  }

  const handleProductsHover = () => {
    setIsDropdownOpen(true);
  };

  const handleProjectDisplay = (title) => {

    const bookingRoute = `/products/${title}`;
   
    window.location.href = bookingRoute;

    setResProduct(false)
    setShowNav(!showNav);
    
  };
  const handleNavLinkClick = () => {
    history.push("/")
    setShowNav(false);
    setLogoActive(false)
  };
  
  const handleProductsLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleProjectClick = (title) => {

    const bookingRoute = `/${title}`;
   
    window.location.href = bookingRoute;
    
  };
  

  useEffect(() => {
    const handleScroll = () => {
      const scrollDock = window.scrollY;

      if (scrollDock > 1) {
        setIsNavActive(true);
      } else {
        setIsNavActive(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const handleResProductClick = () =>{
      setResProduct(!resProduct)
    }

  return (
    <>
      <div className={`nav ${isNavActive ? 'active' : ''}`}>
      <div className="header-left" onClick={()=> handleLogoClick()}>
        <img
            src={logo}
            className={`header-logo`}
            
            alt="" /> 
   
           

      </div>
      <div className="header-right">
        <ScrollLink
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                
                style={{cursor : "pointer"}}
                activeClass={pathname === '/' ? 'active-link' : ''}
                
                onClick={()=> handleLogoClick()} className={`navLinks ${isNavActive ? 'active' : ''}`}>Home</ScrollLink>
        <a href="#" className={`navLinks ${isNavActive ? 'active' : ''}` } onClick={() => handleProjectClick("design-project")}>Design</a>
          <a href="#" className={`navLinks ${isNavActive ? 'active' : ''}` } onClick={() => handleProjectClick("construction-project")}>Construction</a>
          <a href="#" className={`navLinks ${isNavActive ? 'active' : ''}` } onClick={() => handleProjectClick("cnc-project")}>CNC</a>
        <div
          className={`navLinks ${isNavActive ? 'active' : ''} product`}
          onMouseEnter={handleProductsHover}
          onMouseLeave={handleProductsLeave}
        >
          Products 
          {isDropdownOpen && (
            <div className="dropdown-content"  >
              <a href="#" onClick={() =>{handleProjectDisplay("shelves")}}>Shelves</a>
              <a href="#" onClick={() =>{handleProjectDisplay("tables")}}>Tables</a>
              <a href="#" onClick={() =>{handleProjectDisplay("cabinets")}}>Cabinets</a>
              {/* Add more items as needed */}
            </div>
          )}
        </div>
        <a href="#" className={`navLinks ${isNavActive ? 'active' : ''}` } onClick={()=> handleContact()}>Contact</a>
        <CiMenuBurger  className={`sub-menu ${isNavActive ? 'active' : 'active'}` } onClick={() => {setShowNav(!showNav) ; setLogoActive(!logoActive)} }/>
      </div>
      <div className="header-added-logo" onClick={()=> handleLogoClick()}>
        <img src={urban} className='ub-logo' alt="" />
        <img src={furniture} alt="" />
        
      </div>
    </div>
      
    <div  className={`responsive-nav ${showNav ? 'active' : ''} ${inView ? 'visible' : ''}`} >
    <MdOutlineClose className='res-close' onClick={() => {setShowNav(!showNav)} }/>
    
       <ScrollLink
          to='home'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleNavLinkClick}
        >
          Home
        </ScrollLink>
        <ScrollLink
          to='projects'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => handleProjectClick("design-project")}
        >
          Design
        </ScrollLink>
        
        <ScrollLink
          to='team'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => handleProjectClick("construction-project")}
        >
          Construction
        </ScrollLink>
        <ScrollLink
          to='team'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => handleProjectClick("cnc-project")}
        >
          CNC
        </ScrollLink>
        <div
          className={`res-navLinks`}
          onClick={() => handleResProductClick()}
        >
          Products <FaArrowDownLong className='header-down-arrow'/> 
          {resProduct && (
            <div className="res-dropdown-content">
              <a href="#" onClick={() =>{handleProjectDisplay("shelves")}}>Shelves</a>
              <a href="#" onClick={() =>{handleProjectDisplay("tables")}}>Tables</a>
              <a href="#" onClick={() =>{handleProjectDisplay("cabinets")}}>Cabinets</a>
              {/* Add more items as needed */}
            </div>
          )}
        </div>
        <ScrollLink
          to='team'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={()=> handleContact()}
        >
          Contact
        </ScrollLink>
        
      
     
     
  </div> 
 

    </>
    
  )
}

export default Headers