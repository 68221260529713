import React, { useRef, useState } from 'react'
import back1 from '../../imgs/back1.jpg'
import MetaData from "../../MetaData";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { AiOutlineEnter } from "react-icons/ai";
import { FaArrowDownLong } from "react-icons/fa6";
import modern1 from '../../assests/renders/Construction Projects/1/1.jpg';
import modern2 from '../../assests/renders/Construction Projects/2/7.jpg';
import modern3 from '../../assests/renders/Construction Projects/3/1.jpg';
import modern4 from '../../assests/renders/Construction Projects/4/4.jpg';
import modern5 from '../../assests/renders/Construction Projects/5/3.jpg';
import modern6 from '../../assests/renders/Construction Projects/6/3.jpg';
import modern7 from '../../assests/renders/Construction Projects/7/2.jpg';





import "./project.css"

const categories = [
  'Modern',
  'Modern Classical',
  'Classical',
  'Neo classical',
  "Boho"
  
];

const Modern =[
  {
    title:"Kitchen Construction works" , 
    place:"Harisiddhi",
    image:modern1
  },
  {
    title:"Khumaltar Residence" , 
    place:"Harisiddhi",
    image:modern2
  },
  {
    title:"Living Room Construction" , 
    place:"Harisiddhi",
    image:modern3
  },
  {
    title:"Daisy Residence" , 
    place:"Harisiddhi",
    image:modern4
  },
  {
    title:"Pepsicola Residence" , 
    place:"Harisiddhi",
    image:modern5
  },
  {
    title:"Thasi Residence" , 
    place:"Harisiddhi",
    image:modern6
  },
  {
    title:"Office Construction" , 
    place:"Harisiddhi",
    image:modern7
  },

]





function Project() {
  const [activeCategory, setActiveCategory] = useState(0);
  const [isCategoryClicked, setIsCategoryClicked] = useState(activeCategory === 0 ? true : false);
  const bottomRef = useRef(null);

  const { ref: frontTopRef, inView: frontTopInView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });
  console.log(categories)
  const handleCategoryClick = (e, index) => {
    e.preventDefault();
    setActiveCategory(index);
    setIsCategoryClicked(true);
  };

  const handleProjectDisplay = (title) => {

    const bookingRoute = `/project/${title}`;
   
    window.location.href = bookingRoute;
    
  };

  return (
   <> 
   <MetaData title="Construction Project" />
   <div className='project-display-model'>
   <div className="pd-top" style={{backgroundImage:`url(${back1})`}}>
   <div class="text-boxx">
		<h1>Construction Projects</h1>
		<h1>Construction Projects</h1>
	</div>
       <FaArrowDownLong className='project-display-model-arrow'/>
   </div>
   <div className='project-display-model-container'>
   <div className="project-categories model">
   
       </div>
       
     <div className="project-main">
       {
         // Render projects from the Modern array
         Modern.map((project, index) => (
        <div className='project-hover-model'>
             <h3 >{project.title}</h3> 
          <div className="card" onClick={() =>handleProjectDisplay(project.title)}>
            <div className="img">
                <img
                   src={project.image} alt="" 
                 />
            </div>
            <div className="textBox">
              <p className="text head">{project.title}</p>
              <span>View More</span>
             
            </div>
          </div>
        </div>
    //        <div className="box" key={index}>
    //          <h3 >{project.title}</h3>
    //          <h3 className='each-project-title'>{project.title}</h3>
    //          <motion.img
         
    //      whileHover={{ scale: 1.01, transition: { ease: 'circIn', duration: 0.5 } }}
        
    //      src={project.image} alt="" onClick={() =>handleProjectDisplay(project.title)}
    //    />
            
    //        </div>
         ))
      }

     

 
    
       


       {/* Add similar conditions for other categories */}
     </div>
  
   </div>
</div>


   </>
  )
}

export default Project