import React  from 'react'
import { Carousel } from "react-responsive-carousel";
import {cabinets, tables , shelves} from "../../products"
import MetaData from "../../MetaData";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img1 from '../../../src/imgs/homeimg/woodenchair1.jpeg'
import daraz from '../../../src/imgs/daraz.svg'
import img2 from '../../../src/imgs/homeimg/woodenchair2.jpeg'
import img3 from '../../../src/imgs/homeimg/home3.jpg'
import img4 from '../../../src/imgs/homeimg/woodenchair3.jpeg'
import { FaArrowCircleRight } from "react-icons/fa";
import img5 from '../../../src/imgs/homeimg/woodenchair4.jpeg'

import './singleproduct.css'

const selectProductArray = (slug) => {
  switch(slug) {
    
    case 'tables':
      return tables;
    case 'cabinets':
      return cabinets;
    case 'shelves':
      return shelves;
    default:
      return []; // Return an empty array if no matching slug is found
  }
};

const products = [
    img1 , img2,img4,img5 
]


function SingleProduct() {
  const currentHref = window.location.href;
  console.log(currentHref)
  const parts = currentHref.split('/');

// Extract the category and product name
const slug = parts[4]; // 'shelves'
const productsToDisplay = selectProductArray(slug);
const productName = decodeURIComponent(parts[5]); 
const productToShow = productsToDisplay.find(product => product.productName === productName);
console.log(productToShow)


  return (
    <>
    <MetaData title={productName} />
    <div>
        <div className='product-container'>
      <div className='product-pics'>
      
          <Carousel showArrows={true} autoPlay infiniteLoop>
            {productToShow?.images.map((i , index) => (
              <img src={i} key={index} />
            ))}
          </Carousel>
       
      </div>
      <div className="product-details">
        <div className="product-title">
          <h1 className='heading'>
            {productToShow?.productName}
          </h1>
         
          
          <div className="buying-part">
            <div className="buying-left">
              
            
              <h3 className='ocp'>Price - Rs {productToShow?.price}</h3>
              <h3 className='ocp'>Measurement - {productToShow?.measurement}</h3>
              <h3 className='ocp'>Materials -  {productToShow?.Material}</h3>
              <h3 className='ocp'>Style - {productToShow?.Style}</h3>
            </div>
            
          </div>
        </div>
        <ReactMarkdown className="product-desc" remarkPlugins={[remarkGfm]} >
                 {productToShow?.desc ? productToShow?.desc : ""}
                </ReactMarkdown>
        
   

        <div className='visit-div'>

        <h1 className='visit-use'>Visit Us</h1>
        <FaArrowCircleRight className='visit-button'/>
        <div className='daraz-div'>
            <a href={productToShow?.link} target='_blank'>
              <img src={daraz} alt="" />
            </a>
        </div>
        </div>
      </div>
      
    </div>
    </div>
    </>
  )
}

export default SingleProduct