import { BrowserRouter as Router, Route, Switch , useLocation  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./component/Home/Home.jsx";
import Contact from "./component/Contact/Contact.jsx"
import ProjectDisplay from "./component/Headers/ProjectDisplay.jsx"
import CNCDisplay from "./component/Headers/CNCDisplay.jsx"
import Project from "./component/Headers/Project.jsx"
import CProject from "./component/Headers/CProject.jsx"
import CNCProject from "./component/Headers/CNCProject.jsx"
import ProductDisplay from "./component/Headers/ProductDisplay.jsx"
import SingleProduct from "./component/SingleProduct/SingleProduct.jsx"
import PageNotfound from "./component/PageNotfound/PageNotfound.jsx"

import Footer from "./component/Footer/Footer.jsx"
import Headers from "./component/Headers/Headers.jsx"
import { ReactLenis } from '@studio-freight/react-lenis';



import { useEffect, useState } from "react";


function Layout() {
  const location = useLocation(); // Hook to get the current location
  const showHeaderAndFooter = !(
   
    location.pathname === "/changepassword" ||
    location.pathname === "/admin" ||
    location.pathname === "/dashboard" ||
    location.pathname === "/dashboard/viewproject" ||
    location.pathname.startsWith("/dashboard/")
  );
  
  const lenisOptions = {
    lerp: 0.07, // Increase this value for smoother and slower scrolling
    smooth: true,
    direction: 'vertical'
  };

  return (
    <ReactLenis root={true} autoRaf={true} options={lenisOptions}>
       
       {showHeaderAndFooter  && <Headers />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/project/:slug" component={ProjectDisplay} />
        <Route exact path="/projects/cnc" component={CNCDisplay} />
        <Route exact path="/design-project" component={Project} />
        <Route exact path="/construction-project" component={CProject} />
        <Route exact path="/cnc-project" component={CNCProject} />
        <Route exact path="/products/:slug" component={ProductDisplay} />
        <Route exact path="/product/:slug/:product" component={SingleProduct} />
        <Route exact path="/contact" component={Contact} />
        <Route component={PageNotfound} />
      </Switch>
      

      <Footer />
    </ReactLenis>
  );
}

function App() {
  const [loadingg, setloadingg] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setloadingg(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ToastContainer position="top-center" />
     <Layout />
    </Router>
  );
}

export default App;
