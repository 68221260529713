import React, { useEffect } from "react";
import gsap from 'gsap';

import Slugify from "slugify"

export const Product = ({data , slug}) => {
  const { id, productName, price, productImage } = data;
  
 useEffect(() => {
  gsap.from('.skew', {
    duration: 2,
    delay: 0.5,
    opacity: 0,
    y: 100,
    stagger: {
      amount: 0.4
    }
  });
}, []);


const handleProjectDisplay = (title) => {

  const bookingRoute = `/product/${slug}/${title}`;
 
  window.location.href = bookingRoute;
  
};

const slugifyProductName = (productName) => {
  return Slugify(productName, {
    lower: true, // Convert the slug to lowercase
    strict: true // Replace special characters with a dash
  });
};


  return (
    <>
      <div className="card" style={{borderRadius:"0px"}}>
  <div className="card-img" onClick={() =>handleProjectDisplay(productName)}>
    <img src={productImage} alt="" />
  </div>
  <div className="card-info">
    <p className="text-title" style={{color:"black" , fontSize:"1.3rem" , textAlign:"center" , marginBottom:"20px"}}>{productName}</p>
    
  </div>
  <div className="card-footer">
  <span className="text-title" style={{color:"black"}}>Rs {price}</span>
  <div class="card-button" onClick={() =>handleProjectDisplay(productName)} style={{color:"black"}}>View More</div>
</div></div>
    {/* <div className="products">
      <img src={productImage} />
      <div className="descriptions">
        <p>
          <b>{productName}</b>
        </p>
        <p> Rs {price}</p>
      </div>
      <button className="addToCartBttns" onClick={() =>handleProjectDisplay(productName)}>
        View More
      </button>
    </div> */}

    </>
  );
};
