import React, { useCallback, useState }  from 'react'
import { Carousel } from "react-responsive-carousel";
import {cabinets, tables , shelves} from "../../products"
import { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import MetaData from "../../MetaData";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img1 from '../../../src/imgs/CNC JALI PARTITION/1.jpg'
import img2 from '../../../src/imgs/CNC JALI PARTITION/2.jpg'
import img3 from '../../../src/imgs/CNC JALI PARTITION/3.jpg'
import img4 from '../../../src/imgs/CNC JALI PARTITION/4.jpg'
import img5 from '../../../src/imgs/CNC JALI PARTITION/5.jpg'
import img6 from '../../../src/imgs/CNC JALI PARTITION/6.jpg'
import img7 from '../../../src/imgs/CNC JALI PARTITION/7.jpg'
import img8 from '../../../src/imgs/CNC JALI PARTITION/8.jpg'
import img9 from '../../../src/imgs/CNC JALI PARTITION/9.jpg'
import img10 from '../../../src/imgs/CNC JALI PARTITION/10.jpg'
import "./cncdisplay.css"
import daraz from '../../../src/imgs/daraz.svg'

import { FaArrowCircleRight } from "react-icons/fa";





const products = [
    img1 , 
    img2,
    img4,
    img5 ,
    img6 ,
    img7 ,
    img8 ,
    img9 ,
    img10 ,

]


function SingleProduct() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const currentHref = window.location.href;
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


// Extract the category and product name


  return (
    <>
    <MetaData title="CNC Display" />
    <div className='cnc-display-model'>
        <div className='product-container'>
      <div className='product-pics'>
      
          <Carousel showArrows={true} autoPlay infiniteLoop>
            {products.map((i , index) => (
              <img src={i} key={index} />
            ))}
          </Carousel>
       
      </div>
      <div className="product-details">
        <div className="product-title">
          <h1 className='heading'>
          CNC JALI PARTITION
          </h1>
         
          
          <div className="buying-part">
            <div className="buying-left">
              
            
              <h3 className='ocp'>Rates </h3>
              <ul>
                <li>8mm Mdf - Rs 150 per sqft excluding VAT</li>
                <li>10mm Mdf - Rs 200 per sqft excluding VAT</li>
                <li>12mm Mdf - Rs 225 per sqft excluding VAT</li>
                <li>18mm Mdf - Rs 280 per sqft excluding VAT</li>
              </ul>
            </div>
            
          </div>
        </div>
        <ReactMarkdown className="product-desc" remarkPlugins={[remarkGfm]} >
        All of the above rates are without color.
                </ReactMarkdown>
        
        

      </div>
      
    </div>
    
    </div>
    </>
  )
}

export default SingleProduct